

interface StepIndicatorProps {
	steps: string[];
	currentSlide: number;
	setCurrentSlide: (index: number) => void;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ steps, currentSlide, setCurrentSlide }) => (
	<div className="flex justify-center gap-2 mb-6">
		{steps.map((step, index) => (
			<button
				key={index}
				onClick={() => setCurrentSlide(index)}
				className={`px-4 py-2 rounded-lg text-sm ${currentSlide === index
					? 'bg-blue-500 text-white'
					: 'bg-gray-100 text-gray-600'
					}`}
			>
				{step}
			</button>
		))}
	</div>
);

export default StepIndicator;