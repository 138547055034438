// src/LoadingProvider.js
import React, { createContext, useContext, useState } from 'react';

const LoadingContext = createContext({
    isLoading: false,
    showLoading: (message?: string, onCancel?: () => void) => { },
    hideLoading: () => { },
});

export const useLoading = () => useContext(LoadingContext);

interface LoadingProviderProps {
    children: React.ReactNode;
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>('Loading...');
    const [onCancel, setOnCancel] = useState<(() => void) | undefined>(undefined);

    const showLoading = (message?: string, cancelCallback?: () => void) => {
        setIsLoading(true);
        setLoadingMessage(message || 'Loading...');
        setOnCancel(cancelCallback);
    };

    const hideLoading = () => {
        setIsLoading(false);
        setLoadingMessage('Loading...');
        setOnCancel(undefined);
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
        hideLoading();
    };

    return (
        <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
            {children}
            {isLoading && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-5 rounded-lg shadow-lg">
                        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
                        <p className="mt-4 text-gray-700 text-center">{loadingMessage}</p>
                        {onCancel && (
                            <button
                                onClick={handleCancel}
                                className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                </div>
            )}
        </LoadingContext.Provider>
    );
};