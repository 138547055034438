import React, { useRef, useState } from "react";
import { Word } from "../../types";
import "./WordList.css";

interface WordListProps {
	words: Word[];
}

type PopoverState = {
	showPopover: boolean;
	x: number;
	y: number;
	feedback: JSX.Element | null;
};

const hasFeedback = (feedback: any): boolean => {
	if (!feedback) return false;

	if (feedback.Break) {
		if (feedback.Break.ErrorTypes.some((error: string) => error !== "None"))
			return true;
		if (feedback.Break.UnexpectedBreak.Confidence > 0.5) return true;
		if (feedback.Break.MissingBreak.Confidence > 0.5) return true;
	}

	if (feedback.Intonation) {
		if (feedback.Intonation.ErrorTypes.length > 0) return true;
		if (feedback.Intonation.Monotone.SyllablePitchDeltaConfidence > 0.5)
			return true;
	}

	return false;
};

function generateTextFeedback(pronunciationData: Word): JSX.Element {
	if (pronunciationData.PronunciationAssessment.ErrorType === "Omission") {
		return (
			<div>
				<h2 className="text-xl font-bold mb-2">
					Pronunciation Feedback
				</h2>
				<p className="mb-2">
					<strong>Word:</strong> "{pronunciationData.Word}"
				</p>
				<p className="mb-2 text-red-500">
					This word was not said during the recording.
				</p>
			</div>
		);
	}

	return (
		<div>
			<h2 className="text-xl font-bold mb-2">Pronunciation Feedback</h2>
			<p className="mb-2">
				<strong>Word:</strong> "{pronunciationData.Word}"
			</p>
			<p className="mb-2">
				<strong>Overall Accuracy:</strong>{" "}
				{pronunciationData.PronunciationAssessment.AccuracyScore}/100
			</p>

			{pronunciationData.PronunciationAssessment.ErrorType ===
				"Mispronunciation" && (
					<p className="mb-2 text-red-500">
						This word was mispronounced.
					</p>
				)}

			{pronunciationData.PronunciationAssessment.ErrorType ===
				"Insertion" && (
					<p className="mb-2 text-red-500">
						This word should not be said
					</p>
				)}

			{/* Prosody feedback */}
			<div className="mb-4">
				<h3 className="text-lg font-semibold mb-1">Prosody</h3>
				{pronunciationData.PronunciationAssessment.Feedback.Prosody
					.Break.MissingBreak?.Confidence !== undefined &&
					pronunciationData.PronunciationAssessment.Feedback.Prosody
						.Break.MissingBreak.Confidence > 0.5 && (
						<p className="mb-1">
							There should be a slight pause after this word.
						</p>
					)}
				{pronunciationData.PronunciationAssessment.Feedback.Prosody
					.Intonation.Monotone.SyllablePitchDeltaConfidence > 0.5 && (
						<p className="mb-1">
							Try to vary your pitch more when saying this word.
						</p>
					)}
			</div>

			{/* Syllable feedback */}
			<div className="mb-4">
				<h3 className="text-lg font-semibold mb-1">
					Syllable Breakdown
				</h3>
				{pronunciationData.Syllables.map((syllable: any, index: number) => (
					<p key={index} className="mb-1">
						"{syllable.Grapheme}": Accuracy{" "}
						{syllable.PronunciationAssessment.AccuracyScore}/100
					</p>
				))}
			</div>

			{/* Phoneme feedback */}
			<div className="mb-4">
				<h3 className="text-lg font-semibold mb-1">
					Phoneme Breakdown
				</h3>
				{pronunciationData.Phonemes.map((phoneme: any, index: number) => (
					<p key={index} className="mb-1">
						"{phoneme.Phoneme}": Accuracy{" "}
						{phoneme.PronunciationAssessment.AccuracyScore}/100
					</p>
				))}
			</div>

			{/* Problematic phonemes */}
			{pronunciationData.Phonemes.filter(
				(p: any) => p.PronunciationAssessment.AccuracyScore < 60
			).length > 0 && (
					<div>
						<h3 className="text-lg font-semibold mb-1">
							Areas for Improvement
						</h3>
						<p>
							Focus on improving these sounds:{" "}
							{pronunciationData.Phonemes.filter(
								(p: any) => p.PronunciationAssessment.AccuracyScore < 60
							)
								.map((p: any) => p.Phoneme)
								.join(", ")}
						</p>
					</div>
				)}
		</div>
	);
}


const WordList: React.FC<WordListProps> = ({ words }) => {
	const wordRefs = useRef<(HTMLSpanElement | null)[]>([]);
	const [popoverState, setPopoverState] = useState<PopoverState>({
		showPopover: false,
		x: 0,
		y: 0,
		feedback: null,
	});

	const handleWordClick = (e: React.MouseEvent, word: Word) => {
		const feedback = generateTextFeedback(word);
		setPopoverState({
			showPopover: true,
			x: e.clientX,
			y: e.clientY,
			feedback: feedback,
		});
	};

	const handleClickOutside = (e: MouseEvent) => {
		const target = e.target as HTMLElement;
		if (!target.closest('.word-feedback-popover')) {
			setPopoverState(prev => ({ ...prev, showPopover: false }));
		}
	};

	React.useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="flex flex-wrap items-start justify-center p-0 m-0">
			{words.map((word, index) => {
				const { PronunciationAssessment } = word;
				const { ErrorType } = PronunciationAssessment;

				let displayWord = word.Word.trim();
				let tailwindClasses = "inline-block mb-2 mr-2 cursor-pointer";

				if (displayWord.length === 1) {
					displayWord = displayWord.toUpperCase();
				}
				if (ErrorType === "Mispronunciation") {
					tailwindClasses += " bg-orange-300 underline";
				} else if (ErrorType === "Omission") {
					tailwindClasses += " bg-gray-400 text-white";
					displayWord = `[${displayWord}]`;
				} else if (ErrorType === "Insertion") {
					tailwindClasses += " bg-red-500 text-white line-through";
				} else if (hasFeedback(PronunciationAssessment.Feedback)) {
					tailwindClasses += " text-yellow-400";
				}

				return (
					<span
						key={index}
						ref={(el) => (wordRefs.current[index] = el)}
						className={tailwindClasses}
						onClick={(e) => handleWordClick(e, word)}
					>
						{displayWord}
					</span>
				);
			})}

			{popoverState.showPopover && (
				<div
					className="word-feedback-popover fixed bg-white rounded-lg shadow-lg p-4 z-50 max-w-md"
					style={{
						left: `${popoverState.x}px`,
						top: `${popoverState.y}px`,
						transform: 'translate(-50%, 20px)'
					}}
				>
					<div className="whitespace-pre-wrap">
						{popoverState.feedback}
					</div>
				</div>
			)}
		</div>
	);
};

export default WordList;