// StorageContext.tsx
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useAuth } from './useAuth';
import { Preferences } from "@capacitor/preferences";

interface StorageContextType {
	checkStorage: (key: string) => Promise<boolean>;
	saveToStorage: <T>(key: string, value: T) => Promise<void>;
	loadFromStorage: <T>(key: string, defaultValue: T) => Promise<T>;
	deleteFromStorage: (key: string) => Promise<void>;
	saveBlobToStorage: (key: string, blob: Blob) => Promise<void>;
	loadBlobFromStorage: (key: string) => Promise<Blob | null>;
	useLocalStorage: <T>(key: string, initialValue: T) => [T, React.Dispatch<React.SetStateAction<T>>];
	setLocalStorage: <T>(key: string, value: T) => void;
}

const StorageContext = createContext<StorageContextType | undefined>(undefined);

export function StorageProvider({ children }: { children: React.ReactNode }) {
	const { currentUser } = useAuth();
	const userPrefix = currentUser?.uid ? `user_${currentUser.uid}_` : 'anonymous_';

	// Helper function to check if running in browser
	const isBrowser = typeof window !== "undefined";

	// Basic storage interface implementations
	const capacitorStorage = {
		async get(key: string) {
			const { value } = await Preferences.get({ key: userPrefix + key });
			return value;
		},
		async set(key: string, value: string) {
			await Preferences.set({ key: userPrefix + key, value });
		},
		async remove(key: string) {
			await Preferences.remove({ key: userPrefix + key });
		},
	};

	const browserStorage = {
		async get(key: string) {
			return localStorage.getItem(userPrefix + key);
		},
		async set(key: string, value: string) {
			localStorage.setItem(userPrefix + key, value);
		},
		async remove(key: string) {
			localStorage.removeItem(userPrefix + key);
		},
	};

	const storage = isBrowser ? browserStorage : capacitorStorage;

	// Storage utility functions with user prefix
	const checkStorage = async (key: string): Promise<boolean> => {
		const item = await storage.get(key);
		return !!item;
	};

	const saveToStorage = async <T,>(key: string, value: T): Promise<void> => {
		await storage.set(key, JSON.stringify(value));
	};

	const loadFromStorage = async <T,>(key: string, defaultValue: T): Promise<T> => {
		const item = await storage.get(key);
		return item ? JSON.parse(item) : defaultValue;
	};

	const deleteFromStorage = async (key: string): Promise<void> => {
		await storage.remove(key);
	};

	// IndexedDB setup with user prefix
	const DB_NAME = `BlobStorage_${userPrefix}`;
	const STORE_NAME = "blobs";

	const getIndexedDB = async (): Promise<IDBDatabase> => {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open(DB_NAME, 1);
			request.onerror = () => reject(request.error);
			request.onsuccess = () => resolve(request.result);
			request.onupgradeneeded = (event) => {
				const db = (event.target as IDBOpenDBRequest).result;
				db.createObjectStore(STORE_NAME);
			};
		});
	};

	const saveBlobToStorage = async (key: string, blob: Blob): Promise<void> => {
		const arrayBuffer = await blob.arrayBuffer();
		const db = await getIndexedDB();
		return new Promise((resolve, reject) => {
			const transaction = db.transaction(STORE_NAME, "readwrite");
			const store = transaction.objectStore(STORE_NAME);
			const data = { buffer: arrayBuffer, type: blob.type };
			const request = store.put(data, userPrefix + key);
			request.onerror = () => reject(request.error);
			request.onsuccess = () => resolve();
			transaction.oncomplete = () => db.close();
		});
	};

	const loadBlobFromStorage = async (key: string): Promise<Blob | null> => {
		const db = await getIndexedDB();
		return new Promise((resolve, reject) => {
			const transaction = db.transaction(STORE_NAME, "readonly");
			const store = transaction.objectStore(STORE_NAME);
			const request = store.get(userPrefix + key);
			request.onerror = () => reject(request.error);
			request.onsuccess = () => {
				if (!request.result) {
					resolve(null);
					return;
				}
				const blob = new Blob([request.result.buffer], { type: request.result.type });
				resolve(blob);
			};
			transaction.oncomplete = () => db.close();
		});
	};

	const useLocalStorage = <T,>(key: string, initialValue: T) => {
		const [storedValue, setStoredValue] = React.useState<T>(() => {
			try {
				const item = localStorage.getItem(userPrefix + key);
				return item ? JSON.parse(item) : initialValue;
			} catch (error) {
				console.error(error);
				return initialValue;
			}
		});

		React.useEffect(() => {
			try {
				localStorage.setItem(userPrefix + key, JSON.stringify(storedValue));
			} catch (error) {
				console.error(error);
			}
		}, [key, storedValue]);

		const setValue: React.Dispatch<React.SetStateAction<T>> = (value) => {
			try {
				const valueToStore = value instanceof Function ? value(storedValue) : value;
				setStoredValue(valueToStore);
			} catch (error) {
				console.error(error);
			}
		};

		return [storedValue, setValue];
	};

	const setLocalStorage = <T,>(key: string, value: T): void => {
		localStorage.setItem(userPrefix + key, JSON.stringify(value));
	};

	const value = useMemo(
		() => ({
			checkStorage,
			saveToStorage,
			loadFromStorage,
			deleteFromStorage,
			saveBlobToStorage,
			loadBlobFromStorage,
			useLocalStorage,
			setLocalStorage,
		}) as StorageContextType,
		[userPrefix]
	);

	return (
		<StorageContext.Provider value={value}>
			{children}
		</StorageContext.Provider>
	);
}

// Custom hook to use the storage context
export function useStorage() {
	const context = useContext(StorageContext);
	if (context === undefined) {
		throw new Error('useStorage must be used within a StorageProvider');
	}
	return context;
}