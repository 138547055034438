import React, { useState, useEffect } from "react";
import { Message, RoleplaySettingsProps } from "../../types";
import { useAzure } from "../../hooks/useAzure";
import { FaPlay, FaStop } from "react-icons/fa";
import { useServer } from "../../hooks/useServer";
import { useAuth } from "../../hooks/useAuth";
import { useStorage } from "../../hooks/useStorage";

interface MessageItemProps {
	hasFeedback: boolean;
	message: Message;
	index: number;
	settings: RoleplaySettingsProps;
	unblurredSet: Set<number>;
	setUnblurredSet: React.Dispatch<React.SetStateAction<Set<number>>>;
	handleAudio: (index: number) => void;
	isPlayingIndex: number;
}

const MessageItem: React.FC<MessageItemProps> = ({
	hasFeedback,
	message,
	index,
	settings,
	unblurredSet,
	setUnblurredSet,
	handleAudio,
	isPlayingIndex,
}) => {
	const { currentUser } = useAuth();
	const { useLocalStorage } = useStorage();
	const [hasSeenTooltipForBlur, setHasSeenTooltipForBlur] = useLocalStorage('hasSeenTooltipForBlur', false);
	const [showTooltip, setShowTooltip] = useState(false);

	const handleClick = () => {
		if (settings?.outputType === "voice") {
			setUnblurredSet((prev) => {
				const newSet = new Set(prev);
				newSet.has(index) ? newSet.delete(index) : newSet.add(index);
				return newSet;
			});
		}
	};

	useEffect(() => {
		// Show tooltip only for the first message (index 1, after system message)
		// if it's blurred and user hasn't seen the tooltip before
		if (index === 1 && !hasSeenTooltipForBlur && settings?.outputType === "voice" && !unblurredSet.has(index)) {
			setShowTooltip(true);
			// Hide tooltip after 5 seconds
			const timer = setTimeout(() => {
				setShowTooltip(false);
				setHasSeenTooltipForBlur(true);
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [index, hasSeenTooltipForBlur, settings?.outputType, unblurredSet]);

	const isUser = message.role === "user";
	const bubbleClass = isUser
		? "bg-blue-500 text-white"
		: "bg-gray-100 text-gray-800 border border-gray-200";

	return (
		<div
			className={`flex ${isUser ? "justify-end" : "justify-start"
				} items-end space-x-2`}
		>
			{!isUser && (
				<div className="w-8 h-8 rounded-full flex-shrink-0 overflow-hidden">
					<img
						src="profile-photos/woman.jpeg"
						alt="Assistant"
						className="w-full h-full object-cover"
					/>
				</div>
			)}
			<div
				className={`rounded-2xl p-4 max-w-[70%] shadow-sm ${bubbleClass} relative`}
			>
				{showTooltip && (
					<div className="absolute -top-16 left-1/2 transform -translate-x-1/2 bg-black text-white p-2 rounded-lg text-sm animate-bounce shadow-lg">
						Click to reveal the message
						<div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-3 h-3 bg-black"></div>
					</div>
				)}
				<p
					className={`text-sm ${settings?.outputType === "voice" && !unblurredSet.has(index) && !hasFeedback
						? "blur-sm"
						: ""
						}`}
					onClick={handleClick}
				>
					{message.content}
				</p>
				{!isUser && (
					<button
						onClick={() => handleAudio(index)}
						className="absolute bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 inline-flex items-center justify-center rounded-full bg-indigo-400 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors duration-200"
						style={{ width: "24px", height: "24px" }}
					>
						<span className="flex items-center justify-center">
							{isPlayingIndex === index ? (
								<FaStop size={10} />
							) : (
								<FaPlay size={10} />
							)}
						</span>
					</button>
				)}
			</div>
			{isUser && (
				<div className="w-8 h-8 rounded-full bg-blue-500 flex-shrink-0 flex items-center justify-center text-white font-semibold">
					{currentUser?.displayName?.charAt(0).toUpperCase()}
				</div>
			)}
		</div>
	);
};

export default MessageItem;