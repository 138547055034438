import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import { Filesystem, Directory } from "@capacitor/filesystem";
import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";

export interface UserPhoto {
  filepath: string;
  webviewPath?: string;
}

const PHOTO_STORAGE = "photos";

export const takePhoto = async () => {
  const photo = await Camera.getPhoto({
    resultType: CameraResultType.Uri,
    source: CameraSource.Camera,
    quality: 100,
  });

  return photo;
};

export const savePhotoAndCaption = async (
  photo: Photo,
  base64Data: string,
  fileName: string,
  caption: string
) => {
  console.log("Saving photo", fileName);

  await Filesystem.writeFile({
    path: fileName,
    data: base64Data,
    directory: Directory.Data,
  });

  // Retrieve existing photos
  const existingPhotos = await Preferences.get({ key: PHOTO_STORAGE });
  const newPhotos = existingPhotos.value
    ? JSON.parse(existingPhotos.value)
    : [];

  // Create a new entry for this photo
  const newPhotoEntry = {
    filepath: fileName,
    webviewPath: photo.webPath,
    caption: caption,
  };

  newPhotos.push(newPhotoEntry);
  await Preferences.set({
    key: PHOTO_STORAGE,
    value: JSON.stringify(newPhotos),
  });

  return newPhotoEntry;
};

export const getPhotosAndCaptions = async () => {
  const { value } = await Preferences.get({ key: PHOTO_STORAGE });
  const photos = value ? JSON.parse(value) : [];

  for (let photo of photos) {
    try {
      const file = await Filesystem.readFile({
        path: photo.filepath,
        directory: Directory.Data,
      });

      photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
    } catch (error) {
      console.error("Unable to read file", photo, error);
    }
  }

  return photos;
};

export const deletePhoto = async (fileName: string) => {
  try {
    // Delete the photo file from the filesystem
    await Filesystem.deleteFile({
      path: fileName,
      directory: Directory.Data,
    });

    // Retrieve existing photos
    const existingPhotos = await Preferences.get({ key: PHOTO_STORAGE });
    const photos = existingPhotos.value ? JSON.parse(existingPhotos.value) : [];

    // Remove the photo from the list
    const updatedPhotos = photos.filter(
      (photo: UserPhoto) => photo.filepath !== fileName
    );

    // Update the stored photos list
    await Preferences.set({
      key: PHOTO_STORAGE,
      value: JSON.stringify(updatedPhotos),
    });
  } catch (error) {
    console.error("Error deleting photo", fileName, error);
  }
};

export const b64toBlob = (
  b64Data: string,
  contentType = "",
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject("method did not return a string");
      }
    };
    reader.readAsDataURL(blob);
  });
}

export const savePicture = async (
  photo: Photo,
  fileName: string
): Promise<UserPhoto> => {
  const base64Data = await base64FromPath(photo.webPath!);
  await Filesystem.writeFile({
    path: fileName,
    data: base64Data,
    directory: Directory.Data,
  });

  return {
    filepath: fileName,
    webviewPath: photo.webPath,
  };
};
