import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { IoCloseCircle, IoAdd } from "react-icons/io5";
import { useServer } from "../hooks/useServer";
import { useAuth } from "../hooks/useAuth";
import { languageFlags, languages } from "../utils/Helpers";
import { useTour } from "@reactour/tour";
import { useLiveTutor } from "../hooks/LiveTutorProvider";
import { useTranslation } from "react-i18next";
import { getISO_639_1 } from "language-flag-colors";

interface RenderChipsProps {
	items: string[];
	setList: any;
}

interface RenderSuggestionsProps {
	suggestions: string[];
	list: string[];
	setList: any;
}

const pageVariants = {
	initial: {
		opacity: 0,
		x: 100,
	},
	animate: {
		opacity: 1,
		x: 0,
	},
	exit: {
		opacity: 0,
		x: -100,
	},
};

const pageTransition = {
	type: "tween",
	duration: 0.3,
};

const suggestedInterests = [
	"Technology", "Travel", "Cooking", "Music", "Sports",
	"Movies", "Art", "Photography", "Fashion", "Science",
];


const goalMap = [
	"interests",
	"professional",
	"pronunciation",
	"conversation",
]

const OnboardingPage = () => {

	const { i18n, t } = useTranslation();

	const { currentUser } = useAuth();
	const { updateUser, setUser } = useServer();
	const navigate = useNavigate();

	const [step, setStep] = useState(0);
	const [nativeLanguage, setNativeLanguage] = useState("");
	const [interests, setInterests] = useState([]);
	const [currentInput, setCurrentInput] = useState("");

	const [englishLevel, setEnglishLevel] = useState("");
	const [learningGoals, setLearningGoals] = useState<any>([]);
	const [professionalSkills, setProfessionalSkills] = useState([]);

	const totalSteps = 5; // Update total steps

	const suggestedProfessionalSkills = [
		"Presentations",
		"Report Writing",
		"Email Communication",
		"Business Negotiations",
		"Meeting Participation",
		"Customer Service",
		"Technical Documentation",
		"Job Interviews",
	];

	useEffect(() => {
		// Set the language to the user's preferred language
		console.log(nativeLanguage);
		const languageCode = getISO_639_1(nativeLanguage);

		i18n.changeLanguage(languageCode || "en");
	}, [nativeLanguage]);


	const addItem = (item: string, list: string[], setList: any) => {
		if (item && !list.includes(item)) {
			setList((prev: any) => [...prev, item]);
			setCurrentInput("");
		}
	};

	const removeItem = (item: string, setList: any) => {
		setList((prev: any) => prev.filter((i: string) => i !== item));
	};

	const handleNext = () => {
		if (step === totalSteps - 1) {
			handleSubmit();
			return;
		}

		// Validate current step before proceeding
		if (step === 0 && !nativeLanguage) return;
		if (step === 1 && !englishLevel) return;
		if (step === 2 && learningGoals.length === 0) return;
		if (
			step === 3 &&
			learningGoals.includes("interests") &&
			interests.length === 0
		)
			return;
		if (
			step === 4 &&
			learningGoals.includes("professional") &&
			professionalSkills.length === 0
		)
			return;

		// Handle step navigation
		if (step === 2) {
			if (!learningGoals.includes("interests")) {
				if (learningGoals.includes("professional")) {
					setStep(4);
				} else {
					handleSubmit();
				}
			} else {
				setStep(3);
			}
		} else if (step === 3) {
			if (learningGoals.includes("professional")) {
				setStep(4);
			} else {
				handleSubmit();
			}
		} else {
			setStep((prev) => prev + 1);
		}
	};

	const handleSubmit = async () => {
		if (!nativeLanguage) return;
		const { user } = await updateUser({
			nativeLanguage, interests,
			englishLevel,
			learningGoals,
			professionalSkills,
		});
		if (!user) return;
		setUser(user);
		navigate("/liveTutor");
	};

	/*const handleSubmit = async () => {
		if (!nativeLanguage) return;
		const { user } = await updateUser(nativeLanguage, interests);
		if (!user) return;
		setUser(user);
		navigate("/profile");
	};*/

	const renderChips = ({ items, setList }: RenderChipsProps) => (
		<div className="flex flex-wrap justify-center gap-2 mb-4">
			{items.map((item, index) => (
				<div
					key={index}
					onClick={() => removeItem(item, setList)}
					className="flex items-center gap-1 px-3 py-1 rounded-full bg-blue-500 text-white cursor-pointer"
				>
					<span>{t(item)}</span>
					<IoCloseCircle className="w-5 h-5" />
				</div>
			))}
		</div>
	);

	const renderSuggestions = ({ suggestions, list, setList }: RenderSuggestionsProps) => (
		<div className="flex flex-wrap justify-center gap-2 mb-4">
			{suggestions.map((item, index) => (
				<div
					key={index}
					onClick={() => addItem(item, list, setList)}
					className="flex items-center gap-1 px-3 py-1 rounded-full bg-gray-200 cursor-pointer"
				>
					<span>{t(item)}</span>
					<IoAdd className="w-5 h-5" />
				</div>
			))}
		</div>
	);

	const renderStep = () => {
		switch (step) {
			case 0:
				return (
					<AnimatePresence mode="wait">
						<motion.div
							key="step0"
							initial="initial"
							animate="animate"
							exit="exit"
							variants={pageVariants}
							transition={pageTransition}
							className="flex flex-col items-center w-full max-w-md"
						>
							<h2 className="text-3xl font-bold mb-6 text-blue-600">
								{t('lets_get_started')}
							</h2>
							<div className="w-full space-y-6">
								<div className="bg-white rounded-lg p-6 shadow-md">
									<h3 className="text-xl font-semibold mb-4">
										{t('native_language')}
									</h3>
									<select
										value={nativeLanguage}
										onChange={(e) => setNativeLanguage(e.target.value)}
										className="w-full p-2 border rounded-lg"
									>
										<option value="">{t('select_language')}</option>
										{languages.map((lang) => (
											<option key={lang} value={lang}>
												{languageFlags[lang]} {lang}
											</option>
										))}
									</select>
								</div>
							</div>
						</motion.div>
					</AnimatePresence>
				);
			case 1:
				return (
					<AnimatePresence mode="wait">
						<motion.div
							key="step2"
							initial="initial"
							animate="animate"
							exit="exit"
							variants={pageVariants}
							transition={pageTransition}
							className="flex flex-col items-center w-full max-w-md"
						>
							<h2 className="text-3xl font-bold mb-6 text-blue-600">
								{t('english_level')}
							</h2>
							<div className="w-full space-y-4">
								<button
									onClick={() => setEnglishLevel("beginner")}
									className={`w-full p-4 rounded-lg text-left ${englishLevel === "beginner"
										? "bg-blue-600 text-white"
										: "bg-white hover:bg-gray-50"
										}`}
								>
									<h3 className="text-lg font-semibold">{t('beginner')}</h3>
									<p className="text-sm opacity-80">
										{t('beginner_description')}
									</p>
								</button>
								<button
									onClick={() => setEnglishLevel("intermediate")}
									className={`w-full p-4 rounded-lg text-left ${englishLevel === "intermediate"
										? "bg-blue-600 text-white"
										: "bg-white hover:bg-gray-50"
										}`}
								>
									<h3 className="text-lg font-semibold">{t('intermediate')}</h3>
									<p className="text-sm opacity-80">
										{t('intermediate_description')}
									</p>
								</button>
							</div>
						</motion.div>
					</AnimatePresence>
				);
			case 2:
				return (
					<AnimatePresence mode="wait">
						<motion.div
							key="step3"
							initial="initial"
							animate="animate"
							exit="exit"
							variants={pageVariants}
							transition={pageTransition}
							className="flex flex-col items-center w-full max-w-md"
						>
							<h2 className="text-3xl font-bold mb-6 text-blue-600">
								{t('learning_goals')}
							</h2>
							<div className="w-full space-y-4">
								{[
									t('goal_interests'),
									t('goal_professional'),
									t('goal_pronunciation'),
									t('goal_conversation'),
								].map((raw_goal, index) => (
									<button
										key={index}
										onClick={() => {

											const goal = goalMap[index];

											setLearningGoals((prev: any) =>
												prev.includes(goal)
													? prev.filter((g: any) => g !== goal)
													: [...prev, goal]
											)
										}
										}
										className={`w-full p-4 rounded-lg text-left ${learningGoals.includes(goalMap[index])
											? "bg-blue-600 text-white"
											: "bg-white hover:bg-gray-50"
											}`}
									>
										{raw_goal}
									</button>
								))}
							</div>
						</motion.div>
					</AnimatePresence>
				);
			case 3:
				if (learningGoals.includes(goalMap[0])) {
					return (
						<AnimatePresence mode="wait">
							<motion.div
								key="step1"
								initial="initial"
								animate="animate"
								exit="exit"
								variants={pageVariants}
								transition={pageTransition}
								className="flex flex-col items-center w-full max-w-md"
							>
								<h2 className="text-3xl font-bold mb-4 text-blue-600">
									{t('interests')}
								</h2>
								<p className="text-center text-gray-600 mb-6">
									{t('interests_description')}
								</p>
								<div className="bg-white rounded-lg p-6 shadow-md w-full mb-6">
									<input
										type="text"
										value={currentInput}
										onChange={(e) => setCurrentInput(e.target.value)}
										onKeyPress={(e) =>
											e.key === "Enter" &&
											addItem(currentInput, interests, setInterests)
										}
										placeholder={t('type_interest')}
										className="w-full p-2 border rounded-lg text-lg"
									/>
									<button
										onClick={() =>
											addItem(currentInput, interests, setInterests)
										}
										className="w-full mt-4 bg-blue-600 text-white py-2 rounded-lg"
									>
										{t('add_interest')}
									</button>
								</div>
								{renderChips({ items: interests, setList: setInterests })}
								<div className="w-full">
									<h3 className="text-xl font-semibold mb-4 text-center">
										{t('suggested_interests')}
									</h3>
									{renderSuggestions({
										suggestions: suggestedInterests,
										list: interests,
										setList: setInterests,
									})}
								</div>
							</motion.div>
						</AnimatePresence>
					);
				}
				return null;
			case 4:
				if (learningGoals.includes(goalMap[1])) {
					return (
						<AnimatePresence mode="wait">
							<motion.div
								key="step4"
								initial="initial"
								animate="animate"
								exit="exit"
								variants={pageVariants}
								transition={pageTransition}
								className="flex flex-col items-center w-full max-w-md"
							>
								<h2 className="text-3xl font-bold mb-6 text-blue-600">
									{t('professional_skills')}
								</h2>
								<div className="w-full">
									<input
										type="text"
										value={currentInput}
										onChange={(e) => setCurrentInput(e.target.value)}
										onKeyPress={(e) =>
											e.key === "Enter" &&
											addItem(
												currentInput,
												professionalSkills,
												setProfessionalSkills
											)
										}
										placeholder={t('type_skill')}
										className="w-full p-2 border rounded-lg mb-4"
									/>
									{renderChips({
										items: professionalSkills,
										setList: setProfessionalSkills,
									})}
									{renderSuggestions({
										suggestions: suggestedProfessionalSkills,
										list: professionalSkills,
										setList: setProfessionalSkills,
									})}
								</div>
							</motion.div>
						</AnimatePresence>
					);
				}
				return null;
			default:
				return null;
		}
	};

	return (
		<div className="min-h-screen bg-gray-100">
			<header className="bg-blue-600 text-white">
				<h1 className="text-center py-6 text-2xl font-bold">
					{t('welcome')} {currentUser?.displayName}!
				</h1>
				<div className="w-full bg-blue-500 h-1">
					<div
						className="h-full bg-blue-300 transition-all duration-300"
						style={{ width: `${((step + 1) / totalSteps) * 100}%` }}
					></div>
				</div>
			</header>
			<main className="p-4">
				<div className="flex flex-col items-center justify-center min-h-full py-8">
					{renderStep()}
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ delay: 0.3 }}
						className="w-full max-w-md"
					>
						<button
							onClick={handleNext}
							disabled={
								(step === 0 && !nativeLanguage) ||
								(step === 1 && !englishLevel) ||
								(step === 2 && learningGoals.length === 0) ||
								(step === 3 && learningGoals.includes("Learning English related to your interests") && interests.length === 0) ||
								(step === 4 &&
									learningGoals.includes("Improving English for your professional skills") &&
									professionalSkills.length === 0)
							}
							className="w-full mt-8 bg-blue-600 text-white py-3 rounded-lg text-lg font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
						>
							{step === totalSteps - 1 ? t('complete_setup') : t('continue')}
						</button>
					</motion.div>
				</div>
			</main>
		</div>
	);
};

export default OnboardingPage;