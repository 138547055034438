import React, { useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useNavigate } from "react-router-dom";
import { FaFire } from "react-icons/fa";
import { useAuth } from "../hooks/useAuth";
import { useServer } from "../hooks/useServer";
import { useLoading } from "../hooks/LoadingProvider";
import Header from "./livetutor/Header";

const Profile = () => {
	const { isAuthenticated } = useAuth();
	const { user, getUserProfile } = useServer();
	const navigate = useNavigate();
	const { showLoading, hideLoading } = useLoading();

	useEffect(() => {
		if (!user) {
			getUserProfile();
		}
	}, [user]);

	const getStrengthsAndWeaknesses: any = (scores: any) => {
		if (!scores || !scores.overall) {
			return { strengths: ["N/A", { avg: 0 }], weaknesses: ["N/A", { avg: 0 }] };
		}

		const phonemes = Object.entries(scores).filter(([key]) =>
			key !== "overall" &&
			key !== "Listening" &&
			key !== "Speaking" &&
			key !== "Reading" &&
			key !== "Writing"
		);

		if (phonemes.length < 1) {
			return { strengths: ["N/A", { avg: 0 }], weaknesses: ["N/A", { avg: 0 }] };
		}

		const strengths = phonemes.reduce((max: any, p: any) => (p[1].avg > max[1].avg ? p : max));
		const weaknesses = phonemes.reduce((min: any, p: any) => (p[1].avg < min[1].avg ? p : min));

		return { strengths, weaknesses };
	};

	const { strengths, weaknesses } = getStrengthsAndWeaknesses(user?.scores);

	const ScoreGraph = () => {
		if (!user?.scores?.overall || user?.scores?.overall?.length < 1) {
			return null;
		}

		const chartData = user.scores.overall.map((item: any) => ({
			timestamp: new Date(item.timestamp).toLocaleTimeString(),
			avg: item.avg
		}));

		return (
			<div className="bg-white p-4 rounded-lg">
				<h3 className="text-lg font-semibold text-gray-800 mb-4 text-center">
					Overall Pronunciation Score
				</h3>
				<div className="h-[200px] w-full">
					<ResponsiveContainer width="100%" height="100%">
						<LineChart data={chartData}>
							<XAxis dataKey="timestamp" tick={{ fontSize: 12 }} />
							<YAxis
								domain={[85, 87]}
								tickFormatter={(value: number) => `${value}%`}
								tick={{ fontSize: 12 }}
							/>
							<Tooltip
								formatter={(value: number) => [`${value.toFixed(2)}%`, 'Score']}
								labelStyle={{ color: '#666' }}
								contentStyle={{
									backgroundColor: 'white',
									border: '1px solid #ccc',
									borderRadius: '4px',
									padding: '8px'
								}}
							/>
							<Line
								type="monotone"
								dataKey="avg"
								stroke="#2563eb"
								strokeWidth={2}
								dot={{ fill: '#2563eb', r: 4 }}
							/>
						</LineChart>
					</ResponsiveContainer>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (user && !user.interests) {
			navigate("/onboarding");
		}
	}, [user]);

	return (
		<div className="min-h-screen relative pt-20">
			<Header
				exercise={"Profile"}
				back={() => navigate("/liveTutor")}

			/>
			{/* Background */}
			<div
				className="absolute inset-0 z-0 transition-opacity duration-300"
				style={{
					backgroundImage: 'url("tree.png")',
					backgroundSize: 'cover',
					backgroundPosition: 'bottom',
					filter: 'blur(8px)',
					opacity: '0.2',
					transform: 'scale(0.7)',
				}}
			/>

			<main className="relative z-10 overflow-y-hidden">
				<div className="min-h-screen bg-gradient-to-b from-transparent to-white/90">
					{/* Content sections remain the same */}
					{/* Weekly Streak Tracker */}
					<div className="relative max-w-lg mx-auto p-8 flex flex-col items-center">
						<div className="relative">
							<img
								src="fire-flame.gif"
								alt="Fire"
								className="w-32 h-auto mb-4"
							/>
							<div className="absolute top-14 left-1/2 transform -translate-x-1/2">
								<span className="text-6xl font-bold drop-shadow-lg">{user?.streak || 0}</span>
							</div>
						</div>
						<p className="text-lg text-center font-medium text-gray-700 mt-8">
							You're on a hot streak! Keep up the great work!
						</p>
					</div>



					{/* Language Proficiency Levels */}
					<div className="max-w-lg mx-auto p-8 bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg m-4">
						<h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">
							Language Proficiency Levels
						</h2>

						{/* Pronunciation Section */}
						<div className="mb-8 space-y-3">
							<h3 className="text-xl font-semibold text-gray-700">Pronunciation</h3>
							<div className="space-y-2">
								<ScoreGraph />

								<div className="flex items-center justify-between bg-green-50 p-3 rounded-lg">
									<span className="font-medium">Strength:</span>
									<span className="text-lg font-bold text-green-600">
										{strengths[0]} ({strengths[1].avg}%)
									</span>
								</div>
								<div className="flex items-center justify-between bg-red-50 p-3 rounded-lg">
									<span className="font-medium">Weakness:</span>
									<span className="text-lg font-bold text-red-600">
										{weaknesses[0]} ({weaknesses[1].avg}%)
									</span>
								</div>
							</div>
						</div>

						{/* Roleplay Section */}
						<div className="space-y-3">
							<h3 className="text-xl font-semibold text-gray-700">Roleplay</h3>
							<div className="grid grid-cols-2 gap-4">
								<div className="bg-purple-50 p-4 rounded-lg text-center">
									<p className="text-sm text-purple-600 font-medium">Listening</p>
									<p className="text-2xl font-bold text-purple-700">
										{user?.scores?.Listening?.avg || 'NA'}/12
									</p>
								</div>
								<div className="bg-sky-50 p-4 rounded-lg text-center">
									<p className="text-sm text-sky-600 font-medium">Speaking</p>
									<p className="text-2xl font-bold text-sky-700">
										{user?.scores?.Speaking?.avg || 'NA'}/12
									</p>
								</div>
								<div className="bg-amber-50 p-4 rounded-lg text-center">
									<p className="text-sm text-amber-600 font-medium">Reading</p>
									<p className="text-2xl font-bold text-amber-700">
										{user?.scores?.Reading?.avg || 'NA'}/12
									</p>
								</div>
								<div className="bg-indigo-50 p-4 rounded-lg text-center">
									<p className="text-sm text-indigo-600 font-medium">Writing</p>
									<p className="text-2xl font-bold text-indigo-700">
										{user?.scores?.Writing?.avg || 'NA'}/12
									</p>
								</div>
							</div>
						</div>
					</div>


					{/* Achievements Section */}
					<div className="max-w-lg mx-auto p-8 bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg m-4">
						<h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">
							Achievements
						</h2>
						<div className="relative">
							{/* Coming Soon Overlay */}
							<div className="absolute inset-0 flex items-center justify-center z-10 bg-white/60 backdrop-blur-sm rounded-xl">
								<span className="text-xl font-bold text-gray-800">Coming Soon</span>
							</div>

							{/* Achievement Grid (Blurred) */}
							<div className="grid grid-cols-3 gap-6">
								<div className="transform hover:scale-105 transition-transform duration-200">
									<div className="flex flex-col items-center p-4 bg-yellow-50 rounded-xl shadow-sm">
										<img src="badges/streak.png" alt="Streak Badge" className="w-16 h-16" />
										<span className="text-sm mt-2 font-medium text-yellow-700">5-Day Streak</span>
									</div>
								</div>
								<div className="transform hover:scale-105 transition-transform duration-200">
									<div className="flex flex-col items-center p-4 bg-blue-50 rounded-xl shadow-sm">
										<img src="badges/roleplay-master.png" alt="Roleplay Badge" className="w-16 h-16" />
										<span className="text-sm mt-2 font-medium text-blue-700">Roleplay Master</span>
									</div>
								</div>
								<div className="transform hover:scale-105 transition-transform duration-200">
									<div className="flex flex-col items-center p-4 bg-green-50 rounded-xl shadow-sm">
										<img src="badges/pronunciation-expert.png" alt="Pronunciation Badge" className="w-16 h-16" />
										<span className="text-sm mt-2 font-medium text-green-700">Pronunciation Expert</span>
									</div>
								</div>
							</div>
						</div>
					</div>


					{/* Tree at bottom */}
					<div className="relative">
						<img
							src="tree.png"
							alt="Tree"
							className="mx-auto mb-[-20px]"
						/>
					</div>
				</div>
			</main>
		</div>
	);
};

export default Profile;
