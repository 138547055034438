import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import Hero from "../components/landing/Hero";
import Features from "../components/landing/Features";
import Testimonials from "../components/landing/Testimonials";
import Demo from "../components/landing/Demo";
import PricingPlans from "../components/landing/PricingPlans";
import FAQ from "../components/landing/FAQ";
import CallToAction from "../components/landing/CallToAction";
import Footer from "../components/landing/Footer";


const LandingPage: React.FC = () => {

	const demoRef = useRef<HTMLDivElement>(null);
	const pricingRef = useRef<HTMLDivElement>(null);
	const testimonialsRef = useRef<HTMLDivElement>(null);

	const scrollToSection = (sectionId: string) => {
		let section: HTMLElement | null = null;

		if (sectionId === 'demo') {
			section = demoRef.current;
		} else if (sectionId === 'pricing') {
			section = pricingRef.current;
		}
		else if (sectionId === 'testimonials') {
			section = testimonialsRef.current;
		}

		if (section) {
			section.scrollIntoView({ block: "start" });
		}
	};

	return (
		<div className="min-h-screen flex flex-col dark">
			<main className="flex-1">
				<nav className="bg-[#84dbd7] h-14">
					<div className="max-w-6xl mx-auto px-4 flex justify-between items-center h-full">
						{/* Logo section */}
						<div className="flex items-center">
							<img
								src="logo.png"
								alt="Logo"
								className="w-6 h-6 sm:w-8 sm:h-8"
							/>
							<img
								src="wordmark-black.png"
								alt="Wordmark"
								className="h-5 ml-2 sm:h-6"
							/>
						</div>

						{/* Navigation buttons */}
						<div className="flex items-center space-x-2 sm:space-x-4">
							<button
								onClick={() => scrollToSection('pricing')}
								className="px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold hover:text-blue-500 transition-colors"
							>
								Pricing
							</button>
							<button
								onClick={() => scrollToSection('testimonials')}
								className="px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-base font-semibold hover:text-blue-500 transition-colors"
							>
								Reviews
							</button>
						</div>
					</div>
				</nav>

				<Hero />
				<Features />
				<Testimonials ref={testimonialsRef} />
				<Demo ref={demoRef} />
				<PricingPlans ref={pricingRef} />
				<FAQ />
				<CallToAction />
				<Footer />
			</main>
		</div>
	);
};

export default LandingPage;