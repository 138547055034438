import React from 'react';
import { Link } from 'react-router-dom';

const CallToAction: React.FC = () => {
	return (
		<section className="bg-gray-900 text-white py-24">
			<div className="container mx-auto text-center px-4">
				<h2 className="text-4xl font-bold mb-8">Ready to Get Started?</h2>

				<Link to="/liveTutor">
					<button
						className="
              bg-blue-600 
              hover:bg-blue-700 
              text-white 
              text-lg 
              px-10 
              py-3 
              rounded-full 
              font-semibold 
              transition-colors 
              duration-200
              focus:outline-none 
              focus:ring-2 
              focus:ring-blue-500 
              focus:ring-offset-2
            "
					>
						Try it Now!
					</button>
				</Link>
			</div>
		</section>
	);
};

export default CallToAction;