import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoHelpCircleOutline } from "react-icons/io5";
import MessageItem from "./MessageItem";
import FeedbackItem from "./FeedbackItem";
import { Scenario, Message, RoleplaySettingsProps } from "../../types";
import { useAzure } from "../../hooks/useAzure";
import { isObjectEmpty } from "../../utils/Helpers";
import { useLoading } from "../../hooks/LoadingProvider";

interface MessageListProps {
	hasFeedback: boolean;
	scenario: any;
	settings: RoleplaySettingsProps;
	unblurredSet: Set<number>;
	setUnblurredSet: React.Dispatch<React.SetStateAction<Set<number>>>;
	isPlayingIndex: number;
	setIsPlayingIndex: React.Dispatch<React.SetStateAction<number>>;
}

const MessageList: React.FC<MessageListProps> = ({
	hasFeedback,
	scenario,
	settings,
	unblurredSet,
	setUnblurredSet,
	isPlayingIndex,
	setIsPlayingIndex,
}) => {
	const { speakTextAsync, stopTextAsync, auth, ensureToken } = useAzure();
	const { showLoading, hideLoading } = useLoading();

	useEffect(() => {
		return () => {
			setIsPlayingIndex(-1);
			stopTextAsync();
		};
	}, []);

	useEffect(() => {
		if (!auth) {
			ensureToken();
			showLoading();
		} else {
			hideLoading();
		}
	}, [auth]);

	console.log("scenario", scenario);

	const messages = scenario.messages as Message[];

	useEffect(() => {
		const lastMessage = scenario.messages[scenario.messages.length - 1];

		if (lastMessage.role === "assistant" && !hasFeedback) {

			handleAudio(scenario.messages.length - 1);
		}
	}, [scenario?.messages]);

	const handleAudio = (index: number) => {
		if (!scenario) return;

		if (isPlayingIndex === index) {
			stopTextAsync();
			setIsPlayingIndex(-1);
		} else if (isPlayingIndex !== -1) {
			stopTextAsync();
			setIsPlayingIndex(index);
			speakTextAsync({
				text: messages[index].content,
				voice: scenario.voiceId,
				cb: () => setIsPlayingIndex(-1),
			});
		} else {
			setIsPlayingIndex(index);
			speakTextAsync({
				text: messages[index].content,
				voice: scenario.voiceId,
				cb: () => setIsPlayingIndex(-1),
			});
		}
	};

	// Cleanup effect when leaving the page
	useEffect(() => {
		return () => {
			setIsPlayingIndex(-1);
			stopTextAsync();
		};
	}, []);

	return (
		<div className="space-y-6 h-full">
			{messages.map((message, index) => (
				<React.Fragment key={index}>
					{message.role === "system" ? (
						<div className="p-4 bg-blue-50 rounded-lg">
							<h3 className="font-semibold text-lg mb-2">{index == 0 ? 'Try this scenario:' : 'Updated scenario'}</h3>
							<p className="text-gray-700">{message.scenario}</p>
						</div>



					) : (
						<React.Fragment>
							<MessageItem
								hasFeedback={hasFeedback}
								message={message}
								index={index}
								settings={settings}
								unblurredSet={unblurredSet}
								setUnblurredSet={setUnblurredSet}
								handleAudio={handleAudio}
								isPlayingIndex={isPlayingIndex}
							/>
							<FeedbackItem
								message={message}
								feedback={message.feedback}
							/>
						</React.Fragment>
					)}
				</React.Fragment>
			))}
		</div>
	);
};

export default MessageList;