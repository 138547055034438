import React, { FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState, useCallback, useMemo } from "react";
import { HiOutlineExternalLink } from 'react-icons/hi';

const SpeechBubble: FC = () => {
	const gibberish = useMemo(() => {
		// Arrays of squiggly symbols and characters
		const symbols = [
			'∼∼∼⟆⟆≈≈≈',
			'≋≋≋∽∽∽',
			'∿∿∿≈≈≈',
			'〰〰〰⌇⌇⌇',
			'⟿⟿⟿≈≈≈',
			'⥿⥿⥿∼∼∼',
			'⩬⩬⩬≈≈≈',
			'⫶⫶⫶∽∽∽',
		];

		// Generate 1-3 random lines of squiggles
		const numberOfLines = Math.floor(Math.random() * 2) + 1;
		const lines = [];

		for (let i = 0; i < numberOfLines; i++) {
			const line = symbols[Math.floor(Math.random() * symbols.length)];
			lines.push(line);
		}

		return lines;
	}, []);

	return (
		<div className="absolute -top-16 left-1/2 transform -translate-x-1/2">
			<div className="relative">
				<div className="bg-white rounded-lg p-2 shadow-md opacity-70">
					{gibberish.map((line, index) => (
						<div
							key={index}
							className="whitespace-nowrap text-gray-700"
							style={{
								fontSize: '14px',
								lineHeight: '1.2'
							}}
						>
							{line}
						</div>
					))}
					<div
						className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-3 h-3 rotate-45 bg-white"
					/>
				</div>
			</div>
		</div>
	);
};
const Character: React.FC<{
	color: string;
	direction: string;
	id: number;
	position: number;
	chatting: boolean;
	onMove: (id: number, direction: string) => void;
}> = React.memo(({ color, direction, id, position, chatting, onMove }) => {
	useEffect(() => {
		if (chatting) return;

		const interval = setInterval(() => {
			onMove(id, direction);
		}, 50);

		return () => clearInterval(interval);
	}, [id, direction, chatting, onMove]);

	return (
		<div
			className="absolute transition-all duration-300"
			style={{ left: `${position}%`, bottom: '15px' }}
		>
			<div className="relative w-[10px] h-[40px]">
				<div
					className="w-[10px] h-[10px] rounded-full mx-auto"
					style={{ backgroundColor: color }}
				/>
				<div
					className="w-0 h-0 border-l-[5px] border-r-[5px] border-b-[20px] border-l-transparent border-r-transparent"
					style={{ borderBottomColor: color }}
				/>
				{chatting && <SpeechBubble />}
			</div>
		</div>
	);
});

const WalkingCharacters = () => {
	const [positions, setPositions] = useState<Record<number, number>>({ 0: 20, 1: 80, 2: 50, 3: 70 });
	const [directions, setDirections] = useState<Record<number, string>>({ 0: 'left', 1: 'right', 2: 'left', 3: 'right' });
	const [chatting, setChatting] = useState<Record<number, boolean>>({ 0: false, 1: false, 2: false, 3: false });
	const [cooldowns, setCooldowns] = useState<Record<number, boolean>>({ 0: false, 1: false, 2: false, 3: false });

	const positionsRef = useRef(positions);
	const chattingRef = useRef(chatting);

	positionsRef.current = positions;
	chattingRef.current = chatting;

	const handleMove = useCallback((id: number, direction: string) => {
		setPositions((prev) => {
			if (chatting[id]) return prev;

			let newPosition = direction === 'left'
				? prev[id] + 0.3 + id * 0.1
				: prev[id] - 0.5 - id * 0.1;

			// When character reaches the edge, reverse direction and reset position
			if (newPosition >= 110) {
				setDirections(prev => ({ ...prev, [id]: 'right' }));
				newPosition = 110;
			} else if (newPosition <= -10) {
				setDirections(prev => ({ ...prev, [id]: 'left' }));
				newPosition = -10;
			}

			return { ...prev, [id]: newPosition };
		});
	}, [chatting]);

	useEffect(() => {
		const checkProximity = () => {
			const pairs = [[0, 1], [0, 2], [0, 3], [1, 2], [1, 3], [2, 3]];
			pairs.forEach(([a, b]) => {
				const distance = Math.abs(positionsRef.current[a] - positionsRef.current[b]);
				if (
					distance < 5 &&
					!chattingRef.current[a] &&
					!chattingRef.current[b] &&
					!cooldowns[a] &&
					!cooldowns[b]
				) {
					setChatting(prev => ({ ...prev, [a]: true, [b]: true }));

					const chatDuration = 4000 + Math.random() * 2000;
					setTimeout(() => {
						setChatting(prev => ({ ...prev, [a]: false, [b]: false }));
						setCooldowns(prev => ({ ...prev, [a]: true, [b]: true }));

						setTimeout(() => {
							setCooldowns(prev => ({ ...prev, [a]: false, [b]: false }));
						}, 5000);
					}, chatDuration);
				}
			});
		};

		const interval = setInterval(checkProximity, 200);
		return () => clearInterval(interval);
	}, [cooldowns]);

	const characters = useMemo(() => [
		{ color: "#f72585", id: 0 },
		{ color: "#3a0ca3", id: 1 },
		{ color: "#4361ee", id: 2 },
		{ color: "#7209b7", id: 3 },

	], []);

	return (
		<div className="absolute bottom-[5px] left-0 w-full h-[40px]">
			{characters.map(char => (
				<Character
					key={char.id}
					{...char}
					direction={directions[char.id]}
					position={positions[char.id]}
					chatting={chatting[char.id]}
					onMove={handleMove}
				/>
			))}
		</div>
	);
};

const Hero: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className="relative overflow-hidden h-[96vh] bg-[#84dbd7] font-sans">
			{/* Moving clouds */}
			<div className="absolute bottom-[200px] left-0 w-[200%] h-[253px] bg-[url('http://i.imgur.com/WYfbo0O.png')] bg-repeat-x animate-[moveLeftToRight_40s_linear_infinite]"></div>

			{/* Trees, Grass, Buildings */}
			<div className="absolute bottom-[20px] left-1/2 w-[908px] h-[174px] -translate-x-1/2 z-[100] bg-[url('http://i.imgur.com/4JOfJhg.png')] bg-no-repeat bg-center"></div>
			<div className="absolute bottom-[20px] left-1/2 w-[964px] h-[37px] -translate-x-1/2 z-[200] bg-[url('http://i.imgur.com/h0aXbZr.png')] bg-no-repeat bg-center"></div>
			<div className="absolute bottom-0 left-1/2 w-[763px] h-[303px] -translate-x-1/2 bg-[url('http://i.imgur.com/5LmAigM.png')] bg-no-repeat bg-center"></div>

			{/* Ground */}
			<div className="absolute bottom-0 left-0 w-full h-[20px] bg-[#1d1818] z-[999]"></div>

			{/* Walking figures */}
			<WalkingCharacters />

			{/* Title and subtitle */}
			<div className="absolute left-1/2 transform -translate-x-1/2 text-center z-[1000] p-4 md:p-8 rounded-lg mt-0 md:mt-0 lg:mt-4 xl:mt-20 3xl:mt-32">
				<h1 className="text-4xl sm:text-5xl md:text-5xl lg:text-7xl 3xl:text-8xl font-extrabold text-white mb-2">
					Speak <span className="text-blue-500">Confidently,</span> Succeed <span className="text-blue-500">Professionally</span>
				</h1>
				<p className="text-sm sm:text-lg md:text-xl text-white mb-4 opacity-90">
					Bring your future one step closer
				</p>

				<button
					className="bg-blue-500 hover:bg-blue-600 text-black font-bold py-2 px-4 sm:py-3 sm:px-6 rounded-lg transition-transform transform hover:scale-105"
					onClick={() => navigate('/liveTutor')}
				>
					Try Now
				</button>
			</div>

			{/* Credits */}
			<div className="absolute bottom-5 right-1 text-xs text-white z-[300]">
				<a
					href="https://cssdeck.com/labs/bozvpbm3"
					target="_blank"
					rel="noopener noreferrer"
					className="flex items-center hover:text-blue-200 transition-colors"
				>
					Inspired by CSSDeck
					<HiOutlineExternalLink className="ml-1" />
				</a>
			</div>
		</div>
	);
};

export default Hero;