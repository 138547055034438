import React, { useState, useRef, useEffect } from 'react';
import Typed from 'typed.js';
import { useServer } from '../hooks/useServer';
import { getISO_639_1, getLanguage } from "language-flag-colors";

interface PProps {
	children: string;
	className?: string;
	onClick?: () => void;
}

const P: React.FC<PProps> = ({ children, className, onClick }) => {
	const { translate, user } = useServer();
	const [isTranslated, setIsTranslated] = useState(false);
	const [displayText, setDisplayText] = useState(children);
	const typedRef = useRef<Typed | null>(null);
	const elementRef = useRef<HTMLParagraphElement>(null);

	useEffect(() => {
		if (elementRef.current) {
			typedRef.current = new Typed(elementRef.current, {
				strings: [displayText],
				typeSpeed: 10,
				showCursor: false,
			});
		}

		return () => {
			if (typedRef.current) {
				typedRef.current.destroy();
			}
		};
	}, [displayText]);

	const handleTranslate = async () => {
		if (!isTranslated) {
			const languageCode = getISO_639_1(user.nativeLanguage);
			const translatedText = await translate(children, 'auto', languageCode || 'en');
			setDisplayText(translatedText);
			setIsTranslated(true);
		} else {
			setDisplayText(children);
			setIsTranslated(false);
		}
	};

	return (
		<p
			ref={elementRef}
			onClick={() => {
				onClick && onClick();
				handleTranslate();
			}}
			style={{ cursor: 'pointer' }}
			title={isTranslated ? 'Click to show original' : 'Click to translate'}
			className={className}
		/>
	);
};

export default P;