import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import {
	getAuth,
	signInAnonymously,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	signOut,
	linkWithCredential,
	EmailAuthProvider,
	GoogleAuthProvider,
	FacebookAuthProvider,
	signInWithPopup,
	sendPasswordResetEmail,
	updateProfile,
	User,
	Auth,
} from "firebase/auth";
import { registerUser } from "./useServer";

import { initializeApp } from "firebase/app";

initializeApp({
	apiKey: "AIzaSyA-If7X63A3RkIdY83YtwZoDGpzclHnWJs",
	authDomain: "fluent-future.firebaseapp.com",
	projectId: "fluent-future",
	storageBucket: "fluent-future.appspot.com",
	messagingSenderId: "322492694909",
	appId: "1:322492694909:web:6d5b9b44b26c3c8b380c14",
	measurementId: "G-1TBE90ELL0"
});

interface AuthProviderProps {
	children: ReactNode;
}

interface AuthContextType {
	currentUser: User | null;
	loading: boolean;
	loginAnonymously: () => Promise<boolean>;
	forgotPassword: (data: { email: string }) => Promise<boolean>;
	registerWithEmailAndPassword: (data: { name: string, email: string; password: string }) => Promise<boolean>;
	loginWithEmailAndPassword: (data: { email: string; password: string }) => Promise<boolean>;
	loginWithGoogle: () => Promise<boolean>;
	loginWithFacebook: () => Promise<boolean>;
	logout: () => Promise<void>;
	getAccessToken: () => Promise<string | false>;
	isAuthenticated: () => boolean;
	isAnonymous: () => boolean | undefined;
	refreshClaims: () => Promise<any>;

	auth: Auth;
	claims: any | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const auth = getAuth();
	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [claims, setClaims] = useState<any | null>(null);


	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setCurrentUser(user);

			refreshClaims();
		});

		return () => unsubscribe();
	}, [auth]);


	const getAccessToken = async (): Promise<string | false> => {
		if (!auth.currentUser) {
			return false;
		}

		return (await auth.currentUser.getIdTokenResult(false)).token;
	};

	const isAuthenticated = (): boolean => {
		return auth.currentUser !== null;
	};

	const forgotPassword = async (data: { email: string }): Promise<boolean> => {
		try {
			await sendPasswordResetEmail(auth, data.email);
			return true;
		} catch (error) {
			return false;
		}
	};

	const loginAnonymously = async (): Promise<boolean> => {
		try {
			const userCredential = await signInAnonymously(auth);
			if (!userCredential) {
				return false;
			}

			// Send to AWS
			const token = await userCredential.user.getIdToken();
			const response = await registerUser(token);
			if (!response) {
				console.error("Error registering user");
			}

			return true;
		} catch (error) {
			return false;
		}
	};

	const registerWithEmailAndPassword = async (data: { name: string, email: string; password: string }): Promise<boolean> => {
		if (!data.email || !data.password) {
			return false;
		}

		if (auth.currentUser?.isAnonymous) {
			return await linkAnonymousToEmail(data);
		}

		try {
			const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);


			if (!userCredential) {
				return false;
			}

			await updateProfile(userCredential.user, { displayName: data.name });

			// Send to AWS
			const token = await userCredential.user.getIdToken();
			const response = await registerUser(token);

			if (!response) {
				console.error("Error registering user");
			}

			return response.user
		} catch (error) {
			return false;
		}
	};

	const linkAnonymousToEmail = async (data: { name: string, email: string; password: string }): Promise<boolean> => {
		try {
			const credential = EmailAuthProvider.credential(data.email, data.password);
			const userCredential = await linkWithCredential(auth.currentUser!, credential);

			if (!userCredential) {
				return false;
			}

			await updateProfile(userCredential.user, { displayName: data.name });

			await loginWithEmailAndPassword(data);
			return true;
		} catch (error) {
			return false;
		}
	};

	const loginWithEmailAndPassword = async (data: { email: string; password: string }): Promise<boolean> => {
		if (auth.currentUser && auth.currentUser.isAnonymous) {
			await signOut(auth);
		}

		if (auth.currentUser && !auth.currentUser.isAnonymous) {
			return true;
		}

		try {
			const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
			if (!userCredential) {
				return false;
			}

			return true;
		} catch (error) {
			return false;
		}
	};

	const loginWithGoogle = async (): Promise<boolean> => {
		const provider = new GoogleAuthProvider();
		try {
			const result = await signInWithPopup(auth, provider);
			const token = await result.user.getIdToken();

			// Send to AWS or any other logic
			const response = await registerUser(token);
			return !!response;
		} catch (error) {
			console.error("Google login error:", error);
			return false;
		}
	};

	const loginWithFacebook = async (): Promise<boolean> => {
		const provider = new FacebookAuthProvider();
		try {
			const result = await signInWithPopup(auth, provider);
			const token = await result.user.getIdToken();

			// Send to AWS or any other logic
			const response = await registerUser(token);
			return !!response;
		} catch (error) {
			console.error("Facebook login error:", error);
			return false;
		}
	};

	const logout = async (): Promise<void> => {
		if (!auth.currentUser) {
			return;
		}

		try {
			await signOut(auth);
		} catch (error) {
			console.error('Error signing out:', error);
		}
	};

	const isAnonymous = (): boolean | undefined => {
		return auth.currentUser?.isAnonymous;
	};

	const refreshClaims = async (): Promise<any> => {
		if (auth.currentUser) {
			const tokenResult = await auth.currentUser.getIdTokenResult(true); // Force refresh

			setClaims(tokenResult.claims);
			setLoading(false);
			return tokenResult.claims;
		} else {
			setClaims(null);
			setLoading(false);
			return null;
		}
	};

	const value: AuthContextType = {
		currentUser: auth.currentUser,
		loading,
		loginAnonymously,
		forgotPassword,
		registerWithEmailAndPassword,
		loginWithEmailAndPassword,
		loginWithGoogle,
		loginWithFacebook,
		logout,
		getAccessToken,
		isAuthenticated,
		isAnonymous,
		refreshClaims,
		claims,
		auth
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};