import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { RoleplayScores } from "../../types";

interface ScoresListProps {
	scores: RoleplayScores | null;
}

const ScoresList: React.FC<ScoresListProps> = ({ scores }) => {
	if (!scores) return null;

	return (
		<div className="mt-8">
			<h2 className="text-2xl font-bold text-center text-gray-800 mb-4">Scores</h2>
			<div className="space-y-4">
				{Object.entries(scores).map(([key, value]: [any, any]) => (
					<div
						key={key}
						className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-200"
					>
						<div className="px-6 py-4">
							<h3 className="text-xl font-semibold text-gray-800">{key}</h3>
						</div>
						<div className="px-6 py-4">
							<div className="flex items-center space-x-4">
								<div className="w-24 h-24 flex-shrink-0">
									<CircularProgressbar
										value={value.score}
										text={`${value.score}`}
										styles={{
											path: {
												stroke: value.score < 60
													? "#EF4444"
													: value.score < 80
														? "#FBBF24"
														: "#10B981"
											},
											text: {
												fill: "#1F2937",
												fontSize: '24px',
												fontWeight: 'bold'
											},
										}}
									/>
								</div>
								<p className="text-gray-600 flex-1">{value.justification}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ScoresList;