import React from 'react';

interface Props {
	label: string;
	value: number;
}

const LinearProgressWithLabel: React.FC<Props> = ({ label, value }) => {
	const getColor = (value: number) => {
		if (value < 60) return 'bg-red-500'; // danger
		if (value < 80) return 'bg-yellow-500'; // warning
		return 'bg-green-500'; // success
	};

	return (
		<div className="py-0">
			<div className="w-full">
				<div className="flex justify-between mb-1.5">
					<span className="text-gray-500 text-sm">{label}</span>
					<span className="text-gray-500 text-sm">{`${Math.round(value)}%`}</span>
				</div>
				<div className="w-full bg-gray-200 rounded-md h-4">
					<div
						className={`h-full rounded-md transition-all duration-300 ${getColor(value)}`}
						style={{ width: `${value}%` }}
					/>
				</div>
			</div>
		</div>
	);
};

export default LinearProgressWithLabel;