import { Scenario } from "../types";

const scenarios: Scenario[] = [
  {
    id: 1,

    imgSrc: "/scenarios/images/bakery.png",

    messages: [
      {
        role: "system" as const,
        content:
          "You are a friendly bakery employee at a local bakery. You are very helpful and want to make sure the customer is satisfied. Keep your responses short and just output your dialogue.",
        scenarioName: "Bakery Blast",
        task: "Order a baguette in a bakery",
        description: "Try to order a baguette in a bakery",
        roleplay_instructions:
          "You are a friendly bakery employee at a local bakery. You are very helpful and want to make sure the customer is satisfied. Keep your responses short and just output your dialogue.",
      },
      {
        role: "assistant" as const,
        content: "Hello, welcome to the bakery. How can I help you?",
      },
    ],
    sampleVoiceResponse: "/scenarios/audio/bakery.mp3",
    voiceId: "en-US-AriaNeural",

    interest: "food",
    role: "customer",

    inputMode: "Speaking",
    outputMode: "Listening",
  },
  {
    id: 2,
    imgSrc: "/scenarios/images/phone.png",
    messages: [
      {
        role: "system" as const,
        content:
          "You are a customer service agent for a phone provider service. You are very unhelpful and want to make sure the customer is not satisfied. You keep trying to sell them more products instead of cancelling their service like they want. Keep your responses short and just output your dialogue.",
        scenarioName: "Cancel your phone plan",
        task: "Call your phone provider and cancel your plan",
        description: "Call your phone provider and cancel your plan",

        roleplay_instructions:
          "You are a customer service agent for a phone provider service. You are very unhelpful and want to make sure the customer is not satisfied. You keep trying to sell them more products instead of cancelling their service like they want. Keep your responses short and just output your dialogue.",
      },
      {
        role: "assistant" as const,
        content:
          "Hello, welcome to the phone provider service. How can I help you?",
      },
    ],
    sampleVoiceResponse: "/scenarios/audio/phone.mp3",
    voiceId: "en-US-GuyNeural",

    interest: "phone",
    role: "customer",

    inputMode: "Speaking",
    outputMode: "Listening",
  },
  {
    id: 3,

    imgSrc: "/scenarios/images/pizza.png",

    messages: [
      {
        role: "system" as const,
        content:
          "You are a pizza place employee. You are very helpful and want to make sure the customer is satisfied. Keep your responses short and just output your dialogue.",
        scenarioName: "Order a pizza",
        description: "Call a pizza place and order a pizza",
        task: "Call a pizza place and order a pizza",
        roleplay_instructions:
          "You are a pizza place employee. You are very helpful and want to make sure the customer is satisfied. Keep your responses short and just output your dialogue.",
      },
      {
        role: "assistant" as const,
        content: "Hello, welcome to Pizza Palace. How can I help you?",
      },
    ],
    sampleVoiceResponse: "/scenarios/audio/pizza.mp3",
    voiceId: "en-US-SaraNeural",
    interest: "food",
    role: "customer",

    inputMode: "Speaking",
    outputMode: "Listening",
  },
  {
    id: 4,
    imgSrc: "/scenarios/images/interview.png",
    messages: [
      {
        role: "system" as const,
        content:
          "You are a job interviewer. You are very nice and want to make sure the interviewee is comfortable. You want to make sure the interviewee is satisfied and wants to work for your company. Keep your responses short and just output your dialogue.",
        scenarioName: "Job Interview",
        description:
          "You are interviewing for the position of {job_title}. The interviewer is very nice.",
        task: "You are interviewing for the position of {job_title}. The interviewer is very nice.",
        roleplay_instructions:
          "You are a job interviewer. You are very nice and want to make sure the interviewee is comfortable. You want to make sure the interviewee is satisfied and wants to work for your company. Keep your responses short and just output your dialogue.",
      },
      {
        role: "assistant" as const,
        content: "Hello, welcome to the job interview. Tell me about yourself.",
      },
    ],
    sampleVoiceResponse: "/scenarios/audio/interview.mp3",
    voiceId: "en-US-AndrewNeural",

    interest: "job",
    role: "interviewee",

    inputMode: "Speaking",
    outputMode: "Listening",
  },
];

export default scenarios;
