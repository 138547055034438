import React, { Fragment, useState } from "react";
import { IoSettingsOutline, IoArrowBack, IoHelpCircleOutline } from "react-icons/io5";
import { Scenario } from "../../types";
import { getLatestScenarioInfo } from "../../utils/Helpers";

interface ChatHeaderProps {
	scenario: Scenario;
	handleBack: () => void;
	setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
	loading: boolean;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ scenario, handleBack, setShowSettings, loading }) => {
	const [showPopover, setShowPopover] = useState(false);
	const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

	const openPopover = (e: React.MouseEvent) => {
		const rect = e.currentTarget.getBoundingClientRect();
		setPopoverPosition({
			top: rect.bottom + window.scrollY,
			left: rect.left + window.scrollX,
		});
		setShowPopover(true);
	};

	const getContentReason = () => {
		return (
			<span>
				This content is tailored for your interest in{" "}
				<strong>{scenario?.interest}</strong> and your role as{" "}
				<strong>{scenario?.role}</strong>.
			</span>
		);
	};

	const { name } = getLatestScenarioInfo(scenario!.messages);

	return (
		<header className={`bg-white border-b border-gray-200 ${loading ? "blur-sm" : ""}`}>
			<div className="h-14 px-4 flex items-center justify-between">
				<button
					onClick={handleBack}
					className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
				>
					<IoArrowBack className="text-xl" />
				</button>

				<h1 className="text-center font-semibold text-gray-800 flex-1">{name}</h1>

				<div className="flex items-center gap-2">
					{scenario!.interest && scenario!.role && (
						<Fragment>
							<button
								onClick={openPopover}
								className="p-2 text-gray-500 hover:bg-gray-50 rounded-full transition-colors"
							>
								<IoHelpCircleOutline className="text-2xl" />
							</button>

							{showPopover && (
								<div
									className="absolute bg-white shadow-lg rounded-lg p-4 z-50"
									style={{
										top: popoverPosition.top + 10,
										left: popoverPosition.left - 100,
									}}
								>
									<p>{getContentReason()}</p>
								</div>
							)}
						</Fragment>
					)}

					<button
						onClick={() => setShowSettings(true)}
						className="p-2 text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
					>
						<IoSettingsOutline className="text-xl" />
					</button>
				</div>
			</div>
		</header>
	);
};

export default ChatHeader;