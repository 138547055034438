import React, { useState } from 'react';
import { MdMic, MdBusiness } from 'react-icons/md';
import { RiAwardLine } from 'react-icons/ri';

interface Feature {
	title: string;
	icon: React.ComponentType<React.SVGProps<SVGSVGElement>>; // Updated to accept SVG props
	description: string;
}

const features: Feature[] = [
	{
		title: "Real-time Pronunciation Analysis",
		icon: MdMic,
		description: "Receive instant feedback on your pronunciation with our advanced AI technology. Our system analyzes your speech in real-time, providing personalized tips and customized lessons to improve your English pronunciation for professional environments."
	},
	{
		title: "Professional Scenario Simulations",
		icon: MdBusiness,
		description: "Practice your English skills in realistic professional scenarios. From job interviews to business presentations, our simulations provide a safe environment to build confidence and receive constructive feedback on your language use and communication skills."
	},
	{
		title: "CLB Integration",
		icon: RiAwardLine,
		description: "Our platform is fully integrated with the Canadian Language Benchmark (CLB) standards. This alignment ensures that your progress directly translates to recognized proficiency levels, making it easier to meet job requirements and showcase your English skills to potential employers."
	},
];

const Features: React.FC = () => {
	const [selectedFeature, setSelectedFeature] = useState<number>(0);

	return (
		<section className="bg-gradient-to-b from-blue-50 to-white py-20">
			<div className="container mx-auto px-4 max-w-5xl">
				<h2 className="text-4xl font-bold text-center text-blue-800 mb-3">How we can help you</h2>
				<p className="text-xl text-center text-blue-600 mb-10">Elevate your career with our cutting-edge language learning tools</p>

				<div className="grid grid-cols-1 md:grid-cols-3 gap-6">
					{features.map((feature, index) => (
						<div key={index} className="mb-6">
							<div
								onClick={() => setSelectedFeature(index)}
								className={`cursor-pointer transition-all duration-300 hover:shadow-2xl hover:scale-105 h-full rounded-lg p-6
                  ${selectedFeature === index
										? "bg-blue-100 border-blue-500 border-2"
										: "bg-white border border-gray-200"
									}`}
							>
								<div className="flex flex-col items-center text-center">
									{/* Instantiate the icon component properly with props */}
									{React.createElement(feature.icon, { className: "text-5xl mb-4 text-blue-600" } as React.SVGProps<SVGSVGElement>)}
									<h3 className="text-xl font-semibold text-blue-800">{feature.title}</h3>
								</div>
							</div>
						</div>
					))}
				</div>

				<div className="mt-12 p-8 rounded-lg max-w-3xl mx-auto bg-white shadow-xl border border-blue-200">
					<p className="text-lg leading-relaxed text-gray-700">
						{features[selectedFeature].description}
					</p>
				</div>
			</div>
		</section>
	);
};

export default Features;
