import React from "react";
import {
	FaPlayCircle,
	FaPauseCircle,
	FaMicrophone,
	FaHeadphones,
	FaPencilAlt,
	FaEye,
	FaSync
} from "react-icons/fa";
import { Scenario } from "../types";
import { getLatestScenarioInfo } from "../utils/Helpers";

interface ScenarioCardProps {
	scenario: Scenario;
	onClick?: () => void;
	toggleAudio?: (e: React.MouseEvent) => void;
	isPlaying?: boolean;
	generateRoleplayScenario?: () => void;
}

const ScenarioCard: React.FC<ScenarioCardProps> = ({
	scenario,
	onClick,
	toggleAudio,
	isPlaying,
	generateRoleplayScenario,
}) => {
	const { name, task } = getLatestScenarioInfo(scenario.messages);

	const getInputIcon = (mode: "Speaking" | "Writing") => {
		return mode === "Speaking" ? <FaMicrophone /> : <FaPencilAlt />;
	};

	const getOutputIcon = (mode: "Listening" | "Reading") => {
		return mode === "Listening" ? <FaHeadphones /> : <FaEye />;
	};

	return (
		<div
			className="w-full max-w-[280px] relative group"
			onClick={onClick}
		>
			<div className="overflow-hidden rounded-xl border border-gray-100 shadow-sm transition-all duration-300 
        hover:shadow-lg hover:border-primary/30 hover:scale-102 cursor-pointer bg-white">
				<div className="relative h-48">
					{scenario.imgSrc && (
						<img
							className="w-full h-full object-cover"
							src={scenario.imgSrc}
							alt={name}
						/>
					)}
					{scenario.sampleVoiceResponse && (
						<button
							className="absolute top-2 right-2 bg-white bg-opacity-70 rounded-full p-1 hover:bg-white
                shadow-md transition-all duration-300 hover:scale-110 z-10"
							onClick={toggleAudio}
						>
							{isPlaying ? (
								<FaPauseCircle className="text-2xl text-primary" />
							) : (
								<FaPlayCircle className="text-2xl text-primary" />
							)}
						</button>
					)}
					{/* Gradient overlay */}
					<div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent" />
				</div>

				<div className="p-4">
					<h3 className="text-lg font-semibold text-gray-800 mb-2 line-clamp-2">
						{name}
					</h3>
					<p className="text-sm text-gray-600 mb-4 line-clamp-3">
						{task}
					</p>

					<div className="flex justify-between items-center gap-2">
						{scenario.inputMode && (
							<div className="flex items-center gap-1 bg-primary/10 text-primary px-3 py-1 rounded-full text-sm">
								<span className="text-lg">
									{getInputIcon(scenario.inputMode)}
								</span>
								<span>{scenario.inputMode}</span>
							</div>
						)}
						{scenario.outputMode && (
							<div className="flex items-center gap-1 bg-secondary/10 text-secondary px-3 py-1 rounded-full text-sm">
								<span className="text-lg">
									{getOutputIcon(scenario.outputMode)}
								</span>
								<span>{scenario.outputMode}</span>
							</div>
						)}
					</div>
				</div>
			</div>

			{generateRoleplayScenario && (
				<button
					className="absolute -top-2 -right-2 bg-white rounded-full p-2 shadow-lg
            hover:shadow-xl transition-all duration-300 hover:scale-110 z-20"
					onClick={(e) => {
						e.stopPropagation();
						generateRoleplayScenario();
					}}
				>
					<FaSync className="text-2xl text-primary" />
				</button>
			)}
		</div>
	);
};

export default ScenarioCard;