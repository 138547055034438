import React, { forwardRef } from 'react';
import { FaCheckCircle, FaTimesCircle, FaRocket, FaLightbulb } from 'react-icons/fa';

interface PricingPlanProps {
}

const pricingPlans = [
	{
		name: "Basic",
		price: "$9.99/month",
		features: ["Access to all roleplay scenarios", "Daily pronunciation assessments"],
		notIncluded: ["Live conversation mode", "Real-time pronunciation assessments", "Custom roleplay scenarios"],
	},
	{
		name: "Pro",
		price: "$19.99/month",
		features: ["Access to all roleplay scenarios", "Daily pronunciation assessments", "Live conversation mode", "Real-time pronunciation assessments", "Custom roleplay scenarios"],
		notIncluded: [],
		popular: true,
	},
];

const PricingPlans = forwardRef<HTMLDivElement, PricingPlanProps>((props, ref) => (
	<section className="bg-gray-900 py-20" ref={ref}>
		<div className="container mx-auto px-4">
			<p className="font-bold text-center mb-16 text-white">Pricing Plans</p>
			<h2 className="text-4xl font-bold text-center mb-16 text-white">Supercharge yourself!</h2>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
				{pricingPlans.map((plan, index) => (
					<div key={index} className="relative pt-4">
						{plan.popular && (
							<div className="absolute top-3 left-1/2 transform -translate-x-1/2 bg-blue-600 text-white px-4 py-1 rounded-full font-semibold text-sm z-10">
								Popular
							</div>
						)}
						<div className={`flex flex-col shadow-lg hover:shadow-xl transition-shadow duration-300 bg-gray-800 relative ${plan.popular ? "border-2 border-blue-600" : ""} p-6 rounded-lg`}>
							<div className="mb-4">
								<h3 className="text-3xl font-bold text-center text-white">{plan.name}</h3>
							</div>
							<div className="flex-grow">
								<p className="text-4xl font-bold text-center mb-8 text-blue-500">{plan.price}</p>
								<ul className="mb-8">
									{plan.features.map((feature, i) => (
										<li key={i} className="flex items-center mb-4 text-white">
											<FaCheckCircle className="mr-3 text-2xl text-green-500" />
											<span className="text-lg">{feature}</span>
										</li>
									))}
									{plan.notIncluded.map((feature, i) => (
										<li key={i} className="flex items-center mb-4 text-gray-400">
											<FaTimesCircle className="mr-3 text-2xl text-gray-500" />
											<span className="text-lg">{feature}</span>
										</li>
									))}
								</ul>
								<button
									className="w-full py-3 px-6 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors duration-200 flex items-center justify-center text-lg font-semibold"
								>
									{index === 0 ? (
										<FaRocket className="mr-2" />
									) : (
										<FaLightbulb className="mr-2" />
									)}
									Buy Now
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	</section>
));

export default PricingPlans;