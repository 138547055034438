// FeedbackItem.tsx
import React from "react";
import { Message } from "../../types";

interface FeedbackItemProps {
	message: Message;
	feedback: string | undefined;
}

const FeedbackItem: React.FC<FeedbackItemProps> = ({ message, feedback }) => {
	if (message.role !== "user" || !feedback) return null;

	return (
		<div className="text-sm text-gray-500 italic mt-1 text-right pr-12">
			{feedback}
		</div>
	);
};

export default FeedbackItem;