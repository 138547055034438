import React, { useState } from "react";

interface FAQItem {
	question: string;
	answer: string;
}

const faqItems: FAQItem[] = [
	{
		question: "How does the pronunciation analysis work?",
		answer:
			"Our app uses advanced speech recognition to analyze your pronunciation and provides feedback on areas to improve.",
	},
	{
		question: "What languages can I learn in this app?",
		answer:
			"Currently, the app focuses on teaching English. However, we plan to add more languages in the future.",
	},
	{
		question: "How do structured roleplay exercises help with language learning?",
		answer:
			"Roleplay exercises simulate real-life situations, helping you practice conversation in a safe and interactive environment.",
	},
	{
		question: "Is the app free to use?",
		answer:
			"The app offers both free and premium features. The basic language learning tools are free, while advanced tools like in-depth analysis will require a subscription.",
	},
	{
		question: "Can I track my progress?",
		answer:
			"Yes, the app tracks your learning progress and provides detailed feedback on your strengths and areas for improvement.",
	},
];

const FAQ: React.FC = () => {
	const [openIndex, setOpenIndex] = useState<number | null>(null);

	const toggleAccordion = (index: number) => {
		setOpenIndex(openIndex === index ? null : index);
	};

	return (
		<section className="bg-gray-900 text-white py-20">
			<div className="container mx-auto px-4">
				<h2 className="text-4xl font-bold text-center mb-10">
					Frequently Asked Questions
				</h2>

				<div className="max-w-3xl mx-auto">
					{faqItems.map((item, index) => (
						<div
							key={index}
							className="mb-6 border border-gray-700 rounded-lg overflow-hidden"
						>
							<button
								onClick={() => toggleAccordion(index)}
								className="w-full text-left px-6 py-4 flex justify-between items-center hover:bg-gray-800 transition-colors duration-200"
							>
								<span className="font-semibold text-xl">{item.question}</span>
								<span
									className={`transform transition-transform duration-200 ${openIndex === index ? "rotate-180" : ""
										}`}
								>
									▼
								</span>
							</button>
							<div
								className={`transition-all duration-200 ease-in-out ${openIndex === index
										? "max-h-96 opacity-100"
										: "max-h-0 opacity-0 overflow-hidden"
									}`}
							>
								<div className="p-6 bg-gray-800">
									<p className="text-lg leading-relaxed">{item.answer}</p>
								</div>
							</div>
						</div>
					))}
				</div>

				<p className="text-lg leading-relaxed text-center mt-10">
					Have another question? Contact us on{" "}
					<a
						className="underline hover:text-blue-400 transition-colors duration-200"
						href="mailto:info@fluentfuture.ca"
					>
						email
					</a>
					.
				</p>
			</div>
		</section>
	);
};

export default FAQ;