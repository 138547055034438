import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdReplay } from 'react-icons/md';
import debounce from 'lodash/debounce';

interface NavigationArrowsProps {
	steps: any[];
	disabled: boolean;
	currentSlide: number;
	handleNext: () => void;
	handlePrevious: () => void;
}

const NavigationArrows: React.FC<NavigationArrowsProps> = ({ steps, currentSlide, disabled, handleNext, handlePrevious }) => (
	<>
		{/* Desktop Navigation */}
		<div className="hidden md:block max-w-3xl mx-auto">
			<div className="fixed top-1/2 left-1/2 -translate-x-[calc(50%+450px)] -translate-y-1/2 z-10">
				<button
					onClick={handlePrevious}
					disabled={disabled}
					className={`p-4 rounded-full ${(disabled) ? 'bg-gray-200' : 'bg-blue-500 text-white'
						} transition-all hover:scale-110`}
				>
					<FaArrowLeft />
				</button>
			</div>
			<div className="fixed top-1/2 left-1/2 translate-x-[calc(-50%+450px)] -translate-y-1/2 z-10">
				<button
					onClick={handleNext}

					className={`p-4 rounded-full bg-blue-500 text-white
						} transition-all hover:scale-110`}
				>
					<FaArrowRight />
				</button>
			</div>
		</div>

		{/* Mobile Navigation */}
		<div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t p-4">
			<div className="flex justify-between items-center">
				<button
					onClick={handlePrevious}
					disabled={disabled}
					className="p-2"
				>
					<FaArrowLeft />
				</button>
				<span className="text-sm">
					{`${currentSlide + 1}/${steps.length}`}
				</span>
				<button
					onClick={handleNext}

					className="p-2"
				>
					<FaArrowRight />
				</button>
			</div>
		</div>
	</>
);

export default NavigationArrows;
