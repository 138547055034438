import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHeadphones, FaMicrophone, FaBook, FaPencilAlt, FaArrowLeft } from 'react-icons/fa';
import { useServer } from '../../hooks/useServer';
import { Scenario } from '../../types';
import { useLoading } from '../../hooks/LoadingProvider';
import { getLatestScenarioInfo } from '../../utils/Helpers';
import { useStorage } from '../../hooks/useStorage';

const CreateRoleplayPage: React.FC = () => {
	const { generateRoleplayScenario } = useServer();
	const { useLocalStorage } = useStorage();
	const [scenarioDescription, setScenarioDescription] = useState('');
	const [inputMode, setInputMode] = useState<'Speaking' | 'Writing'>('Speaking');
	const [outputMode, setOutputMode] = useState<'Listening' | 'Reading'>('Listening');
	const [generatedScenario, setGeneratedScenario] = useState<Scenario | null>(null);

	const { name, task, description } = getLatestScenarioInfo(generatedScenario?.messages || []);
	const { showLoading, hideLoading } = useLoading();
	const navigate = useNavigate();

	const [currentRoleplayScenario, setCurrentRoleplayScenario] =
		useLocalStorage<Scenario | null>("currentRoleplayScenario", null);

	const generateScenario = async () => {
		try {
			showLoading('Generating scenario...');
			const generatedScenario = await generateRoleplayScenario(
				inputMode,
				outputMode,
				scenarioDescription || undefined
			);
			setGeneratedScenario(generatedScenario);
		} catch (error) {
			console.error('Error generating scenario:', error);
		} finally {
			hideLoading();
		}
	};

	const playScenario = () => {
		setCurrentRoleplayScenario(generatedScenario);
		navigate("/chat");
	};

	return (
		<div className="min-h-screen bg-gray-100">
			<header className="bg-white shadow">
				<div className="px-4 py-4 flex items-center">
					<button
						onClick={() => navigate('/roleplay')}
						className="mr-4"
					>
						<FaArrowLeft className="text-gray-600" />
					</button>
					<h1 className="text-xl font-semibold">Create Your Own Roleplay</h1>
				</div>
			</header>

			<main className="p-4">
				<div className="max-w-md mx-auto">
					<div className="bg-white rounded-lg shadow p-4 mb-4">
						<textarea
							className="w-full p-2 mb-4 border border-gray-300 rounded-md"
							placeholder="Describe the scenario that you want to practice..."
							value={scenarioDescription}
							onChange={(e) => setScenarioDescription(e.target.value)}
							rows={4}
						/>

						<div className="mb-4">
							<label className="block mb-2">Input Mode</label>
							<select
								value={inputMode}
								onChange={e => setInputMode(e.target.value as 'Speaking' | 'Writing')}
								className="w-full p-2 border border-gray-300 rounded-md"
							>
								<option value="Writing">
									<FaPencilAlt className="inline mr-2" /> Writing
								</option>
								<option value="Speaking">
									<FaMicrophone className="inline mr-2" /> Speaking
								</option>
							</select>
						</div>

						<div className="mb-4">
							<label className="block mb-2">Output Mode</label>
							<select
								value={outputMode}
								onChange={e => setOutputMode(e.target.value as 'Listening' | 'Reading')}
								className="w-full p-2 border border-gray-300 rounded-md"
							>
								<option value="Listening">
									<FaHeadphones className="inline mr-2" /> Listening
								</option>
								<option value="Reading">
									<FaBook className="inline mr-2" /> Reading
								</option>
							</select>
						</div>

						<p className="text-sm text-gray-500 mb-4">
							Input Mode: How you'll interact with the scenario.<br />
							Output Mode: How the scenario will present information to you.
						</p>

						<button
							onClick={generateScenario}
							className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 mb-4"
						>
							Generate Scenario
						</button>
					</div>

					{generatedScenario && (
						<div className="bg-white rounded-lg shadow p-4 mb-4">
							<h2 className="text-xl font-semibold mb-2">{name}</h2>
							<div className="mb-2">
								<strong>Task:</strong> {task}
							</div>
							<div>
								<strong>Description:</strong> {description}
							</div>
						</div>
					)}

					<button
						onClick={playScenario}
						disabled={!generatedScenario}
						className={`w-full py-2 px-4 rounded-md ${generatedScenario
							? 'bg-green-600 text-white hover:bg-green-700'
							: 'bg-gray-300 text-gray-500 cursor-not-allowed'
							}`}
					>
						Play Scenario
					</button>
				</div>
			</main>
		</div>
	);
};

export default CreateRoleplayPage;