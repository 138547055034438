import React, { forwardRef } from 'react';

interface DemoProps {
  // Define props here if needed
}

const Demo = forwardRef<HTMLDivElement, DemoProps>((props, ref) => (
  <section className="theme-dark py-20" ref={ref}>
    <div className="container mx-auto px-4 max-w-3xl">
      <div className="flex flex-col md:flex-row items-center justify-center">
        <div className="w-full md:w-1/2 mb-12 md:mb-0 md:mr-6">
          <div className="rounded-lg overflow-hidden shadow-2xl">
            <video controls className="w-full">
              <source src="demo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="w-full md:w-1/2 md:ml-6">
          <p className="text-xl leading-relaxed">
            Here's a brief message from our founder about the app's mission and vision. We're dedicated to providing the best experience for our users.
          </p>
        </div>
      </div>
    </div>
  </section>
));

Demo.displayName = 'Demo';

export default Demo;