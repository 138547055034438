import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

const TermsAndConditions: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div className="min-h-screen flex flex-col">
			<header className="bg-white border-b">
				<div className="px-4 h-14 flex items-center">
					<button
						onClick={() => navigate(-1)}
						className="flex items-center text-blue-600 p-2"
					>
						<IoArrowBack className="text-xl" />
						<span className="ml-1">Back</span>
					</button>
					<h1 className="text-lg font-semibold mx-auto">Terms and Conditions</h1>
				</div>
			</header>

			<main className="flex-1 overflow-auto p-4">
				<div className="max-w-3xl mx-auto">
					<h1 className="text-2xl font-bold mb-4">Terms and Conditions for Fluent Future</h1>

					<div>
						<p className="mb-4">
							Last updated: 2024-10-08
						</p>

						<p className="mb-4">
							Please read these Terms and Conditions carefully before using the Fluent Future application.
						</p>

						<h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
						<p className="mb-4">
							By accessing or using Fluent Future, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the application.
						</p>

						<h2 className="text-xl font-semibold mb-2">2. Use of the Application</h2>
						<p className="mb-4">
							Fluent Future is a language learning application designed for newcomers in Canada. You agree to use the application only for its intended purpose and in compliance with all applicable laws and regulations.
						</p>

						<h2 className="text-xl font-semibold mb-2">3. User Accounts</h2>
						<p className="mb-4">
							You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.
						</p>

						<h2 className="text-xl font-semibold mb-2">4. Content and Data Collection</h2>
						<p className="mb-4">
							By using Fluent Future, you consent to the collection and use of your pronunciation data and audio recordings as described in our Privacy Policy. You retain all rights to your personal data, and we will only use it in accordance with our Privacy Policy.
						</p>

						<h2 className="text-xl font-semibold mb-2">5. Intellectual Property</h2>
						<p className="mb-4">
							The content, features, and functionality of Fluent Future are owned by us and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
						</p>

						<h2 className="text-xl font-semibold mb-2">6. Limitation of Liability</h2>
						<p className="mb-4">
							Fluent Future and its creators shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the application.
						</p>

						<h2 className="text-xl font-semibold mb-2">7. Changes to the Application</h2>
						<p className="mb-4">
							We reserve the right to withdraw or amend Fluent Future, and any service or material we provide via the application, in our sole discretion without notice.
						</p>

						<h2 className="text-xl font-semibold mb-2">8. Governing Law</h2>
						<p className="mb-4">
							These Terms shall be governed and construed in accordance with the laws of Canada, without regard to its conflict of law provisions.
						</p>

						<h2 className="text-xl font-semibold mb-2">9. Changes to Terms</h2>
						<p className="mb-4">
							We reserve the right to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.
						</p>

						<h2 className="text-xl font-semibold mb-2">10. Contact Us</h2>
						<p className="mb-4">
							If you have any questions about these Terms, please contact us at:
						</p>
						<p className="mb-4">
							Fluent Future<br />
							Email: info@fluentfuture.ca<br />
						</p>
					</div>
				</div>
			</main>
		</div>
	);
};

export default TermsAndConditions;