import React, { useState } from "react";
import { motion } from "framer-motion";

interface LessonStep {
	type: "Text" | "Practice Saying" | "Audio" | "Image";
	content: any;
}

interface LessonProps {
	title: string;
	steps: LessonStep[];
}

const Lesson: React.FC<LessonProps> = ({ title, steps }) => {
	const [visibleSteps, setVisibleSteps] = useState(1);

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: 0.2,
			},
		},
	};

	const itemVariants = {
		hidden: { opacity: 0, y: 20 },
		visible: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.5,
			},
		},
	};

	const renderStep = (step: LessonStep, index: number) => {
		return (
			<motion.div
				key={index}
				variants={itemVariants}
				className="bg-white rounded-lg shadow-sm p-6 mb-6 border-l-4 border-blue-500"
			>
				{step.type === "Text" && (
					<p className="text-gray-700 leading-relaxed">{step.content}</p>
				)}
				{step.type === "Practice Saying" && (
					<div className="bg-green-50 p-4 rounded-lg border border-green-100">
						<p className="font-medium text-green-800 mb-2">Practice saying:</p>
						<p className="text-green-900">{step.content}</p>
					</div>
				)}
				{step.type === "Audio" && (
					<div className="bg-purple-50 p-4 rounded-lg border border-purple-100">
						<p className="font-medium text-purple-800 mb-3">Listen:</p>
						<audio controls className="w-full">
							<source src={step.content.url} type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</div>
				)}
				{step.type === "Image" && (
					<div className="bg-orange-50 p-4 rounded-lg border border-orange-100">
						<img
							src={step.content.url}
							alt={step.content.alt}
							className="w-full rounded-lg shadow-sm"
						/>
					</div>
				)}
			</motion.div>
		);
	};

	return (
		<div className="mx-auto px-4 py-12  min-h-screen">
			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				className="text-center mb-12"
			>
				<h1 className="text-3xl font-bold text-gray-800 mb-4">{title}</h1>
				<div className="h-1 w-20 bg-blue-600 mx-auto rounded-full"></div>
			</motion.div>

			<motion.div
				variants={containerVariants}
				initial="hidden"
				animate="visible"
				className="space-y-6"
			>
				{steps.slice(0, visibleSteps).map((step, index) => renderStep(step, index))}
			</motion.div>

			{visibleSteps < steps.length && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					className="mt-8 text-center"
				>
					<button
						onClick={() => setVisibleSteps(prev => prev + 1)}
						className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition-colors duration-300"
					>
						Next
					</button>
				</motion.div>
			)}
		</div>
	);
};

export default Lesson;