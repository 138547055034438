import React from 'react';
import { MdMic, MdTextFields, MdClose } from 'react-icons/md';
import { RoleplaySettingsProps } from "../../types";

interface RoleplaySettingsComponentProps {
	showSettings: boolean;
	setShowSettings: (showSettings: boolean) => void;
	settings: RoleplaySettingsProps;
	setSettings: (
		settings:
			| RoleplaySettingsProps
			| ((prevSettings: RoleplaySettingsProps) => RoleplaySettingsProps)
	) => void;
}

const RoleplaySettings: React.FC<RoleplaySettingsComponentProps> = ({
	showSettings,
	setShowSettings,
	settings,
	setSettings,
}) => {
	const handleSettingsChange = (
		setting: keyof RoleplaySettingsProps,
		value: any
	) => {
		setSettings((prevSettings: RoleplaySettingsProps) => {
			if (prevSettings) {
				return { ...prevSettings, [setting]: value };
			}
			return prevSettings;
		});
	};

	if (!showSettings) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
			<div className="bg-white rounded-lg w-full max-w-md mx-4">
				<header className="border-b border-gray-200">
					<div className="flex items-center justify-between p-4">
						<h2 className="text-xl font-semibold">Settings</h2>
						<button
							onClick={() => setShowSettings(false)}
							className="p-2 hover:bg-gray-100 rounded-full"
						>
							<MdClose size={24} />
						</button>
					</div>
				</header>

				<div className="p-4">
					<ul className="space-y-4">
						<li className="flex items-center justify-between">
							<span>Show Suggestions</span>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									className="sr-only peer"
									checked={settings?.showSuggestions || false}
									onChange={(e) =>
										handleSettingsChange(
											"showSuggestions",
											e.target.checked
										)
									}
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</li>

						<li className="flex items-center justify-between">
							<span>Speak</span>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									className="sr-only peer"
									checked={settings?.speak || false}
									onChange={(e) =>
										handleSettingsChange(
											"speak",
											e.target.checked
										)
									}
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</li>

						<li className="space-y-2">
							<span>Speaking Speed</span>
							<div className="flex items-center space-x-2">
								<span>0.5x</span>
								<input
									type="range"
									min="0.5"
									max="2"
									step="0.1"
									value={settings?.speakingSpeed || 1}
									onChange={(e) =>
										handleSettingsChange(
											"speakingSpeed",
											parseFloat(e.target.value)
										)
									}
									className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
								/>
								<span>2x</span>
							</div>
						</li>

						<li className="flex items-center justify-between">
							<span>Live Conversation</span>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									className="sr-only peer"
									checked={settings?.liveConversation || false}
									onChange={(e) =>
										handleSettingsChange(
											"liveConversation",
											e.target.checked
										)
									}
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</li>

						<li>
							<span>Input Type</span>
							<div className="flex space-x-4 mt-2">
								<button
									className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${settings?.inputType === "text"
											? "bg-blue-600 text-white"
											: "bg-gray-200"
										}`}
									onClick={() =>
										handleSettingsChange("inputType", "text")
									}
								>
									<MdTextFields size={20} />
									<span>Text</span>
								</button>
								<button
									className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${settings?.inputType === "voice"
											? "bg-blue-600 text-white"
											: "bg-gray-200"
										}`}
									onClick={() =>
										handleSettingsChange("inputType", "voice")
									}
								>
									<MdMic size={20} />
									<span>Voice</span>
								</button>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default RoleplaySettings;