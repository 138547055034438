import React, { useState, useCallback, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdReplay } from 'react-icons/md';
import { Lesson } from '../../../types';
import debounce from 'lodash/debounce';
import { useLiveTutor } from '../../../hooks/LiveTutorProvider';
interface BasicLessonProps {
	lesson: Lesson;
	onComplete?: () => void;

}

const BasicLessonFlow: React.FC<BasicLessonProps> = ({ lesson, onComplete }) => {

	const { handleChat, systemMessageRef } = useLiveTutor();

	const [currentSlide, setCurrentSlide] = useState(0);
	const [currentExpressionIndex, setCurrentExpressionIndex] = useState(0);

	const totalSlides = 4;
	const steps = ['Key Words', 'Expressions', 'Conversation', 'Cultural'];

	const debouncedHandleChat = useCallback(
		debounce((message, add_to_log = true) => {
			handleChat(message, add_to_log);
		}, 3000),
		[handleChat]
	);

	const updateSystemMessage = (slide: number) => {
		let message = `You are a friendly tutor named Sarah. You are teaching English to beginners. 
	
They are learning about ${lesson.topic}. 

# INSTRUCTIONS:
- IMPORTANT: The exercise has just changed! Please go through the following new exercise with the user.
- Keep your responses VERY short and conversational.`;

		switch (slide) {
			case 0: // Previously case 1
				message += `# WHAT THE USER SEES:
- Key Words: ${lesson.key_words.join(', ')}
- Image description: ${lesson.key_words_image.alt}
- Objective: Describe the situation in the picture using the key words.`;
				break;
			case 1: // Previously case 2
				message += `# WHAT THE USER SEES:
- Key Expression: ${lesson.key_expressions[currentExpressionIndex].expression}
- Image description: ${lesson.key_expressions[currentExpressionIndex].image.alt}
- Objective: The user practices saying the sentence and understand what it means.`;
				break;
			case 2: // Previously case 3
				message += `
# THE USER SEE'S THE FOLLOWING CONVERSATION:
${lesson.conversation.map(d => d.role + ":" + d.content).join('\n')}

# ADDITIONAL INSTRUCTIONS:
- Practice lines in the conversation with the user.
- Pick a role and take turns reading the lines with the user.
- If the user says a line, then you can respond with just the next line.`;
				break;
			case 3: // Previously case 4
				message += `# NEW EXERCISE
- Discuss the following cultural question with the user.
            
# WHAT THE USER SEES:
- Cultural Question: ${lesson.cultural_question}
- Objective: Discuss question in detail.`;
				break;
		}

		systemMessageRef.current = message;
		debouncedHandleChat('Start off the exercise with the user.', false);

	};

	useEffect(() => {
		updateSystemMessage(currentSlide);
	}, [currentSlide, currentExpressionIndex]);


	const handleNext = () => {
		if (currentSlide < totalSlides - 1) {

			if (currentSlide === 1 && currentExpressionIndex < lesson.key_expressions.length - 1) {
				setCurrentExpressionIndex(currentExpressionIndex + 1);
			} else {
				const nextSlide = currentSlide + 1;
				setCurrentSlide(nextSlide);
				setCurrentExpressionIndex(0);

			}

		} else {
			console.log('Lesson completed!');
			onComplete?.();
		}
	};

	const handlePrevious = () => {
		if (currentSlide > 0) {
			if (currentSlide === 1 && currentExpressionIndex > 0) {
				setCurrentExpressionIndex(currentExpressionIndex - 1);
			} else {
				const prevSlide = currentSlide - 1;
				setCurrentSlide(prevSlide);
				setCurrentExpressionIndex(0);
			}
		}
	};

	const StepIndicator = () => (
		<div className="flex justify-center gap-2 mb-6">
			{steps.map((step, index) => (
				<button
					key={index}
					onClick={() => setCurrentSlide(index)}
					className={`px-4 py-2 rounded-lg text-sm ${currentSlide === index
						? 'bg-blue-500 text-white'
						: 'bg-gray-100 text-gray-600'
						}`}
				>
					{step}
				</button>
			))}
		</div>
	);

	const NavigationArrows = () => (
		<>
			{/* Desktop Navigation */}
			<div className="hidden md:block max-w-2xl mx-auto">
				<div className="fixed top-1/2 left-1/2 -translate-x-[calc(50%+384px)] -translate-y-1/2 z-10">
					<button
						onClick={handlePrevious}
						disabled={currentSlide === 0}
						className={`p-4 rounded-full ${currentSlide === 0 ? 'bg-gray-200' : 'bg-blue-500 text-white'
							} transition-all hover:scale-110`}
					>
						<FaArrowLeft />
					</button>
				</div>
				<div className="fixed top-1/2 left-1/2 translate-x-[calc(-50%+384px)] -translate-y-1/2 z-10">
					<button
						onClick={handleNext}

						className={`p-4 rounded-full bg-blue-500 text-white
							 transition-all hover:scale-110`}
					>
						<FaArrowRight />
					</button>
				</div>
			</div>

			{/* Mobile Navigation */}
			<div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t p-4">
				<div className="flex justify-between items-center">
					<button
						onClick={handlePrevious}
						disabled={currentSlide === 0}
						className="p-2"
					>
						<FaArrowLeft />
					</button>
					<span className="text-sm">
						{currentSlide === 2
							? `${currentExpressionIndex + 1}/${lesson.key_expressions.length}`
							: `${currentSlide + 1}/${totalSlides}`}
					</span>
					<button
						onClick={handleNext}

						className="p-2"
					>
						<FaArrowRight />
					</button>
				</div>
			</div>
		</>
	);

	const renderSlide = () => {

		switch (currentSlide) {

			case 0:
				return (
					<div className="flex flex-col items-center space-y-6 max-w-2xl mx-auto p-6 bg-gray-50 rounded-xl shadow-md">
						<h2 className="text-3xl font-bold text-gray-800">Picture Description: {lesson.topic}</h2>
						<div className="w-full space-y-4">
							<p className="text-lg text-gray-700">
								Look at the picture and describe what you see. Use the key words below to help you make sentences.
							</p>

						</div>
						<div className="w-full">
							<p className="text-lg font-semibold text-gray-700 mb-2">Key words:</p>
							<div className="flex flex-wrap gap-2">
								{lesson.key_words.map((word: string, index: number) => (
									<span
										key={index}
										className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium hover:bg-blue-200 transition-colors duration-200"
									>
										{word}
									</span>
								))}
							</div>
						</div>
						<img
							src={lesson.key_words_image.src}
							alt={lesson.key_words_image.alt}
							className="w-full h-auto max-h-96 object-cover rounded-lg shadow-lg"
						/>


					</div>
				);
			case 1:
				return (
					<div className="flex flex-col items-center space-y-6 max-w-2xl mx-auto p-6 bg-gray-50 rounded-xl shadow-md">
						<h3 className="text-2xl font-bold text-gray-800">Key Expressions</h3>

						<p className="text-gray-600 text-center">
							Practice saying the following sentence:
						</p>

						<div className="bg-white p-6 rounded-lg shadow-sm w-full">
							<p className="text-xl font-medium text-gray-800 mb-4 text-center">
								{lesson.key_expressions[currentExpressionIndex].expression}
							</p>
							<img
								src={lesson.key_expressions[currentExpressionIndex].image.src}
								alt={lesson.key_expressions[currentExpressionIndex].image.alt}
								className="w-full h-80 object-cover rounded-lg shadow-md"
							/>
						</div>



						<p className="text-sm text-gray-500 mt-4 text-center">
							Progress: {currentExpressionIndex + 1} / {lesson.key_expressions.length}
						</p>
					</div>
				);
			case 2: // Conversation
				return (
					<div className="flex flex-col space-y-4 max-w-2xl mx-auto">
						<h3 className="text-xl font-semibold text-center mb-4">Conversation Practice</h3>
						<p className="text-center text-lg text-gray-600 border-b-2 pb-4 mb-4
						">Practice the following conversation with your tutor:</p>
						<div className="space-y-4">
							{lesson.conversation.map((dialogue: any, index: number) => (
								<div
									key={index}
									className={`flex ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}
								>
									<div className={`flex ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'} max-w-[80%]`}>
										<div className={`w-10 h-10 rounded-full flex items-center justify-center text-white font-bold ${index % 2 === 0 ? 'bg-blue-500 mr-3' : 'bg-green-500 ml-3'}`}>
											{dialogue.role.charAt(0).toUpperCase()}
										</div>
										<div className={`p-3 rounded-lg ${index % 2 === 0 ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'}`}>
											<p className="font-semibold mb-1">{dialogue.role.charAt(0).toUpperCase() + dialogue.role.slice(1)}</p>
											<p>{dialogue.content}</p>
											{dialogue.audio && (
												<button className="mt-2 text-blue-500 hover:text-blue-700 transition-colors">
													<MdReplay className="inline mr-1" /> Play Audio
												</button>
											)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				);

			case 3: // Cultural Question
				return (
					<div className="flex flex-col items-center">
						<h3 className="text-xl font-semibold mb-3">Share Your Thoughts</h3>

						<p className="text-center text-lg font-semibold mt-12">{lesson.cultural_question}</p>
						<img
							src={lesson.lesson_image.src}
							alt={lesson.lesson_image.alt}
							className="w-64 h-64 object-cover rounded-lg shadow-md mb-6"
						/>



					</div>
				);

			default:
				return null;
		}
	};

	return (
		<div className="max-w-2xl mx-auto p-6 mb-16 md:mb-0">
			<StepIndicator />
			<div className="min-h-[500px] bg-white rounded-lg shadow-lg p-6">
				{renderSlide()}
			</div>
			<NavigationArrows />
		</div>
	);
};

export default BasicLessonFlow;