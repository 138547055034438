import React, { useState, useCallback, useEffect, useRef } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdReplay } from 'react-icons/md';
import debounce from 'lodash/debounce';
import { useLiveTutor } from '../../../hooks/LiveTutorProvider';
import RoleplayConversation from '../whiteboard/speaking/RoleplayConversation';
import PronunciationPracticePage from '../whiteboard/Pronunciation';
import FillInTheBlanks from './vocabulary/FillInTheBlanks';
import { Dialogue } from '../../../types';
import { useStorage } from '../../../hooks/useStorage';
import { useLoading } from '../../../hooks/LoadingProvider';
import { useServer } from '../../../hooks/useServer';
import { useToast } from '../../../hooks/ToastProvider';

interface PronunciationLessonProps {
	pronunciationContent: any;
	onComplete?: () => void;
}

const Progress = ({ current, total }: { current: number; total: number }) => {
	const percentage = ((current + 1) / total) * 100;

	return (
		<div className="mb-6">
			<div className="flex justify-between text-sm text-gray-600 mb-2">
				<span>Progress</span>
				<span>{current + 1} of {total}</span>
			</div>
			<div className="w-full h-2 bg-gray-200 rounded-full">
				<div
					className="h-full bg-blue-500 rounded-full transition-all duration-300 ease-in-out"
					style={{ width: `${percentage}%` }}
				/>
			</div>
		</div>
	);
};

const PronunciationLesson: React.FC<PronunciationLessonProps> = ({
	pronunciationContent,
	onComplete
}) => {
	const { showLoading, hideLoading } = useLoading();
	const { showToast } = useToast();
	const { postPronunciationAssessmentResults } = useServer();
	const { loadBlobFromStorage, deleteFromStorage } = useStorage();

	const { systemMessageRef } = useLiveTutor();
	const [currentPronunciationIndex, setCurrentPronunciationIndex] = useState(0);
	const resultsRef = useRef<any[]>([]);

	useEffect(() => {
		let message = `You are a friendly tutor named Sarah. You are teaching English to beginners.
		
# INSTRUCTIONS:
This exercise is about pronunciation.

- Help the user practice pronouncing the words correctly

# WHAT THE USER SEES:
- Word: ${pronunciationContent.content[currentPronunciationIndex].word}`;

		systemMessageRef.current = message;
	}, [currentPronunciationIndex]);

	const handleNext = () => {
		if (currentPronunciationIndex < pronunciationContent.content.length - 1) {
			setCurrentPronunciationIndex(currentPronunciationIndex + 1);
		} else {
			console.log('Lesson completed!');
			submitPronunciationAssessment();
		}
	};

	const handlePrevious = () => {
		if (currentPronunciationIndex > 0) {
			setCurrentPronunciationIndex(currentPronunciationIndex - 1);
		}
	};


	const NavigationArrows = () => (
		<>
			{/* Desktop Navigation */}
			<div className="hidden md:block max-w-2xl mx-auto">
				<div className="fixed top-1/2 left-1/2 -translate-x-[calc(50%+384px)] -translate-y-1/2 z-10">
					<button
						onClick={handlePrevious}
						disabled={currentPronunciationIndex === 0}
						className={`p-4 rounded-full ${currentPronunciationIndex === 0 ? 'bg-gray-200' : 'bg-blue-500 text-white'
							} transition-all hover:scale-110`}
					>
						<FaArrowLeft />
					</button>
				</div>
				<div className="fixed top-1/2 left-1/2 translate-x-[calc(-50%+384px)] -translate-y-1/2 z-10">
					<button
						onClick={handleNext}

						className={`p-4 rounded-full bg-blue-500 text-white
							} transition-all hover:scale-110`}
					>
						<FaArrowRight />
					</button>
				</div>
			</div>

			{/* Mobile Navigation */}
			<div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t p-4">
				<div className="flex justify-between items-center">
					<button
						onClick={handlePrevious}
						disabled={currentPronunciationIndex === 0}
						className="p-2"
					>
						<FaArrowLeft />
					</button>
					<span className="text-sm">
						{`${currentPronunciationIndex + 1}/${pronunciationContent.content.length}`}
					</span>
					<button
						onClick={handleNext}

						className="p-2"
					>
						<FaArrowRight />
					</button>
				</div>
			</div>
		</>
	);

	const submitPronunciationAssessment = async () => {
		showLoading("Submitting results...");

		const audioPromises = resultsRef.current.map(
			(result) => result && loadBlobFromStorage(result.audio)
		);

		// Wait for all audio blobs to load
		Promise.all(audioPromises)
			.then(async (audioBlobs) => {
				// if assessment
				await postPronunciationAssessmentResults(
					resultsRef.current,
					pronunciationContent.content.map((word: any) => word.word),
					"session1",
					audioBlobs,
				);

				// Delete all audio blobs
				await Promise.all(
					resultsRef.current.map(
						(result) =>
							result && deleteFromStorage(result.audio)
					)
				);

				onComplete?.();
			})
			.catch((error) => {

				showToast("Error submitting results");
				console.error("Error loading audio blobs", error);
			}).finally(() => {
				hideLoading();
			});
	};



	return (
		<div className="max-w-2xl mx-auto p-6 mb-16 md:mb-0 mt-10">
			<Progress
				current={currentPronunciationIndex}
				total={pronunciationContent.content.length}
			/>

			<div className="space-y-6">
				<PronunciationPracticePage
					pronunciationProgress={{
						name: "Practice Session",
						sessionId: "session1",
						speechElements: pronunciationContent.content,
						isAssessment: pronunciationContent.metric ? false : true,
						isFrequency: false,
						role: "student",
						interest: "general",
						video: pronunciationContent.video,
					}}
					onComplete={() => handleNext()}
					currentPronunciationIndex={currentPronunciationIndex}
					resultsRef={resultsRef}
				/>

			</div>
			<NavigationArrows />
		</div>
	);
};

export default PronunciationLesson;