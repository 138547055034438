import React, { useState } from 'react';
import { useLiveTutor } from '../../../../hooks/LiveTutorProvider';

interface VocabularyProps {
	wordBank: string[];
	sentences: string[];
	answers: string[];
}

const Vocabulary: React.FC<VocabularyProps> = ({ wordBank, sentences, answers }) => {
	const { handleChat, systemMessageRef } = useLiveTutor();
	const [userAnswers, setUserAnswers] = useState<string[]>(Array(answers.length).fill(''));
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [usedWords, setUsedWords] = useState<Set<string>>(new Set());

	const handleWordClick = (word: string) => {
		if (!usedWords.has(word)) {
			const newUserAnswers = [...userAnswers];
			// If there was a previous word in this blank, remove it from usedWords
			if (newUserAnswers[selectedIndex]) {
				setUsedWords(prev => {
					const updated = new Set(prev);
					updated.delete(newUserAnswers[selectedIndex]);
					return updated;
				});
			}
			newUserAnswers[selectedIndex] = word;
			setUserAnswers(newUserAnswers);
			setUsedWords(prev => new Set(prev).add(word));

			// Move to next empty box
			const nextEmptyIndex = userAnswers.findIndex((answer, idx) => idx > selectedIndex && !answer);
			if (nextEmptyIndex !== -1) {
				setSelectedIndex(nextEmptyIndex);
			}
		}
	};

	const handleBoxClick = (index: number) => {
		setSelectedIndex(index);
	};

	const removeWord = (index: number) => {
		const word = userAnswers[index];
		if (word) {
			setUsedWords(prev => {
				const updated = new Set(prev);
				updated.delete(word);
				return updated;
			});
			const newUserAnswers = [...userAnswers];
			newUserAnswers[index] = '';
			setUserAnswers(newUserAnswers);
		}
	};

	const checkAnswers = () => {

		const isCorrect = answers.every((answer, index) => answer === userAnswers[index]);
		//alert(isCorrect ? 'Correct!' : 'Try again!');
		systemMessageRef.current = `You are a friendly tutor named Sarah. You are teaching English to beginners.

# INSTRUCTIONS:
- The exercise has just changed! Please guide the user through the new exercise.
- Keep your responses VERY short and conversational.

# CURRENT EXERCISE: Fill in the Blanks
- Guide the user to complete the sentences

# Exercise Content:
${sentences.map((sentence, index) => `- Sentence ${index + 1}: ${sentence}`).join('\n')}

# User Answers:
${userAnswers.map((answer, index) => `- Answer ${index + 1}: ${answer}`).join('\n')}

${isCorrect ? 'The answers are correct!' : 'The answers are incorrect. Please try again.'}

`;

		console.log(systemMessageRef.current);

		handleChat('How did I do?', false);
	};

	return (
		<div className="bg-gray-100 p-4">
			<div className="max-w-4xl mx-auto">
				<header className="bg-white shadow-sm mb-4 rounded-lg">
					<div className="px-4 py-5">
						<h1 className="text-xl font-semibold text-gray-800">Vocabulary Practice</h1>
					</div>
				</header>

				<div className="bg-white rounded-lg shadow-md p-6">
					{/* Word Bank */}
					<div className="mb-6">
						<h2 className="text-lg font-semibold mb-3">Word Bank</h2>
						<div className="flex flex-wrap gap-2 p-4 border-2 border-dashed border-gray-300 rounded-lg">
							{wordBank.map((word, index) => (
								<button
									key={index}
									onClick={() => handleWordClick(word)}
									className={`px-3 py-2 rounded-md ${usedWords.has(word)
										? 'bg-gray-200 line-through text-gray-500'
										: 'bg-blue-100 hover:bg-blue-200'
										}`}
									disabled={usedWords.has(word)}
								>
									{word}
								</button>
							))}
						</div>
					</div>

					{/* Sentences */}
					<div className="space-y-4">
						{sentences.map((sentence, index) => (
							<div key={index} className="flex items-center gap-2">
								<span>{sentence}</span>
								<div
									onClick={() => handleBoxClick(index)}
									className={`inline-block min-w-[120px] h-10 border-2 rounded-md cursor-pointer 
                    flex items-center justify-center transition-all duration-200
                    ${selectedIndex === index
											? 'border-blue-500 bg-blue-50 shadow-md'
											: 'border-gray-300 hover:border-blue-300'
										}`}
								>
									{userAnswers[index] && (
										<div className="flex items-center gap-2 px-2">
											<span>{userAnswers[index]}</span>
											<button
												onClick={(e) => {
													e.stopPropagation();
													removeWord(index);
												}}
												className="text-red-500 hover:text-red-700"
											>
												×
											</button>
										</div>
									)}
								</div>
							</div>
						))}
					</div>

					{/* Submit Button */}
					<button
						onClick={checkAnswers}
						className="w-full mt-6 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 
              focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
					>
						Check Answers
					</button>
				</div>
			</div>
		</div>
	);
};

export default Vocabulary;