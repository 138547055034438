// contexts/HotStreakContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useServer } from '../hooks/useServer';
import { motion, AnimatePresence } from 'framer-motion';

interface HotStreakContextType {
	showHotStreak: () => void;
}

const HotStreakContext = createContext<HotStreakContextType | undefined>(undefined);

export const useHotStreak = () => {
	const context = useContext(HotStreakContext);
	if (!context) {
		throw new Error('useHotStreak must be used within a HotStreakProvider');
	}
	return context;
};

export const HotStreakProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { user } = useServer();
	const [previousGamification, setPreviousGamification] = useState<any>(null);
	const [hotStreakData, setHotStreakData] = useState<any>({
		show: false,
		activeDays: new Set(),
		startDate: null,
	});


	function getDaysArray() {
		return [1, 2, 3, 4, 5];
	}

	function getWeekday(dayNumber: number) {
		if (!hotStreakData.startDate) return '';

		const date = new Date(hotStreakData.startDate);
		date.setDate(date.getDate() + (dayNumber - 1));
		const day = date.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'UTC' });
		return day.substring(0, 2).toUpperCase();
	}


	const showHotStreak = () => {
		if (!user?.gamification) return;
		if (!user.gamification.startDate || !user.gamification.practiced) return;

		const { practiced, startDate: newStartDate } = user.gamification;

		if (newStartDate && practiced) {
			setHotStreakData({
				startDate: new Date(newStartDate),
				activeDays: new Set(practiced),
				show: true,
			});
		}
	};

	useEffect(() => {
		setPreviousGamification(user?.gamification);
		if (!previousGamification) {
			return;
		}

		if (user?.gamification !== previousGamification) {
			showHotStreak();
		}
	}, [user?.gamification]);

	useEffect(() => {
		if (hotStreakData.show) {
			setTimeout(() => {
				setHotStreakData((prev: any) => ({ ...prev, show: false }));

			}, 5000);
		}
	}, [hotStreakData.show]);

	const daysArray = getDaysArray();

	return (
		<HotStreakContext.Provider value={{ showHotStreak }}>
			{children}
			<AnimatePresence>
				{hotStreakData.show && (
					<motion.div
						className="fixed top-16 left-0 w-full flex justify-center z-60 pointer-events-none"
						initial={{ opacity: 0, y: -50 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -50 }}
						transition={{ duration: 0.5 }}
					>
						<motion.div
							className={`
                            bg-gradient-to-br from-indigo-600 via-violet-600 to-purple-600
                            rounded-b-2xl shadow-lg p-6
                            max-w-md w-11/12 pr-0 pl-0
                            border-b border-x border-white/10
                        `}
							initial={{ scale: 0.9 }}
							animate={{ scale: 1 }}
							transition={{ duration: 0.3 }}
						>
							<motion.div
								className="flex items-center justify-center mb-4"
								initial={{ y: -20 }}
								animate={{ y: 0 }}
								transition={{ delay: 0.2 }}
							>
								<motion.div
									className="bg-white/10 backdrop-blur-sm rounded-full px-6 py-2 flex items-center"
									whileHover={{ scale: 1.05 }}
									transition={{ type: "spring", stiffness: 400, damping: 10 }}
								>
									<motion.span
										className="text-xl mr-2"
										animate={{ rotate: [0, -10, 10, -10, 10, 0] }}
										transition={{ duration: 1.5, delay: 0.5 }}
									>
										🔥
									</motion.span>
									<span className="text-white font-semibold tracking-wide">
										{user?.gamification?.streak || 0} Day Streak!
									</span>
								</motion.div>
							</motion.div>

							<div className="flex justify-center gap-3">
								{daysArray.map((day, index) => (
									<motion.div
										key={day}
										className="flex flex-col items-center"
										initial={{ opacity: 0, y: 20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ delay: 0.1 * index }}
									>
										<motion.div
											className={`
                                            w-11 h-11 sm:w-14 sm:h-14
                                            rounded-xl
                                            flex items-center justify-center
                                            ${hotStreakData.activeDays.has(day)
													? 'bg-white shadow-lg'
													: 'bg-white/10 border border-white/20'}
                                            backdrop-blur-sm
                                        `}
											whileHover={{ scale: 1.1 }}
											whileTap={{ scale: 0.95 }}
											transition={{ type: "spring", stiffness: 400, damping: 17 }}
										>
											{hotStreakData.activeDays.has(day) && (
												<motion.div
													initial={{ scale: 0 }}
													animate={{ scale: 1 }}
													transition={{ type: "spring", stiffness: 200, damping: 10 }}
												>
													<FaCheckCircle className="text-indigo-600 text-xl sm:text-2xl" />
												</motion.div>
											)}
										</motion.div>
										<motion.span
											className={`
                                            text-xs sm:text-sm mt-2
                                            ${hotStreakData.activeDays.has(day) ? 'text-white font-medium' : 'text-white/60'}
                                            tracking-wider
                                        `}
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											transition={{ delay: 0.2 * index }}
										>
											{getWeekday(day)}
										</motion.span>
									</motion.div>
								))}
							</div>

							<motion.div
								className="mt-6 text-center"
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ delay: 0.8 }}
							>
								<span className="text-white/70 text-sm font-medium tracking-wide">
									{(user?.gamification?.streak || 0) > 1
										? 'Keep going! You\'re on a roll!'
										: 'Start your streak today!'}
								</span>
							</motion.div>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</HotStreakContext.Provider>
	);
};