import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	MdDarkMode,
	MdNotifications,
	MdVolumeUp,
	MdLogout,
	MdDownload,
	MdCheck,
	MdPerson
} from "react-icons/md";
import { useAuth } from "../hooks/useAuth";
import { useStorage } from "../hooks/useStorage";
import { useServer } from "../hooks/useServer";

import { generateUUID } from "../utils/Helpers";
import { useToast } from "../hooks/ToastProvider";

const Settings = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { showToast } = useToast();
	const { user, getPronunciationAssessmentContent, downloadBuild } = useServer();
	const { useLocalStorage, saveToStorage } = useStorage();

	const [darkMode, setDarkMode] = useLocalStorage("darkMode", false);
	const [notificationsEnabled, setNotificationsEnabled] = useLocalStorage(
		"notifications",
		true
	);
	const [soundEnabled, setSoundEnabled] = useLocalStorage("sound", true);
	const { logout, isAuthenticated, claims } = useAuth();

	const toggleDarkMode = () => {
		showToast("Dark mode is not available yet");
	};

	const toggleNotifications = () => {
		showToast("Notifications are not available yet");
	};

	const toggleSound = () => {
		showToast("Sound is not available yet");
	};

	const handleDownloadApp = async (platform: string) => {
		const url = await downloadBuild(platform);
		window.open(url, "_blank");
	};

	return (
		<div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
			<div className="px-4 py-6">
				<header className="mb-6">
					<h1 className="text-2xl font-bold">{t("settings")}</h1>
				</header>

				<div className="space-y-6">
					{/* Preferences Section */}
					<div className="bg-white rounded-2xl shadow-sm p-4">
						<h2 className="text-lg font-semibold text-indigo-900 mb-4">
							{t("preferences")}
						</h2>

						<div className="space-y-4">
							{/* Dark Mode Toggle */}
							<div className="flex items-center justify-between p-3 rounded-xl bg-indigo-50">
								<div className="flex items-center gap-3">
									<MdDarkMode className="text-xl text-indigo-600" />
									<span className="font-medium text-gray-700">Dark Mode</span>
								</div>
								<button
									onClick={toggleDarkMode}
									className={`w-12 h-6 rounded-full ${darkMode ? "bg-indigo-600" : "bg-gray-300"
										} relative transition-colors`}
								>
									<span
										className={`absolute top-1 ${darkMode ? "right-1" : "left-1"
											} w-4 h-4 bg-white rounded-full transition-all`}
									/>
								</button>
							</div>

							{/* Notifications Toggle */}
							<div className="flex items-center justify-between p-3 rounded-xl bg-indigo-50">
								<div className="flex items-center gap-3">
									<MdNotifications className="text-xl text-indigo-600" />
									<span className="font-medium text-gray-700">Notifications</span>
								</div>
								<button
									onClick={toggleNotifications}
									className={`w-12 h-6 rounded-full ${notificationsEnabled ? "bg-indigo-600" : "bg-gray-300"
										} relative transition-colors`}
								>
									<span
										className={`absolute top-1 ${notificationsEnabled ? "right-1" : "left-1"
											} w-4 h-4 bg-white rounded-full transition-all`}
									/>
								</button>
							</div>

							{/* Sound Toggle */}
							<div className="flex items-center justify-between p-3 rounded-xl bg-indigo-50">
								<div className="flex items-center gap-3">
									<MdVolumeUp className="text-xl text-indigo-600" />
									<span className="font-medium text-gray-700">Sound</span>
								</div>
								<button
									onClick={toggleSound}
									className={`w-12 h-6 rounded-full ${soundEnabled ? "bg-indigo-600" : "bg-gray-300"
										} relative transition-colors`}
								>
									<span
										className={`absolute top-1 ${soundEnabled ? "right-1" : "left-1"
											} w-4 h-4 bg-white rounded-full transition-all`}
									/>
								</button>
							</div>
						</div>
					</div>

					{/* Live Tutor Button */}
					<div className="bg-white rounded-2xl shadow-sm p-4">
						<button
							onClick={() => navigate("/liveTutor")}
							className="w-full py-3 bg-indigo-600 text-white rounded-xl hover:bg-indigo-700 transition-colors flex items-center justify-center gap-2"
						>
							<MdPerson className="text-xl" />
							{t("liveTutor")}
						</button>
					</div>

					{/* Download Section */}
					<div className="bg-white rounded-2xl shadow-sm p-4">
						<div className="flex items-center justify-between p-3 rounded-xl bg-indigo-50">
							<div className="flex items-center gap-3">
								<MdDownload className="text-xl text-indigo-600" />
								<span className="font-medium text-gray-700">Download App</span>
							</div>
							<div className="flex gap-2">
								<button
									onClick={() => handleDownloadApp("android")}
									className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
								>
									{t("android")}
								</button>
								<button
									onClick={() => handleDownloadApp("ios")}
									className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
								>
									{t("ios")}
								</button>
							</div>
						</div>
					</div>

					{/* Admin Section */}
					{claims?.admin && (
						<div className="bg-white rounded-2xl shadow-sm p-4">
							<h2 className="text-lg font-semibold text-indigo-900 mb-4">
								{t("admin")}
							</h2>
							<div className="space-y-4">
								<button
									onClick={() => navigate("/onboarding")}
									className="w-full p-3 bg-indigo-50 text-gray-700 rounded-xl hover:bg-indigo-100 transition-colors flex items-center justify-between"
								>
									<span className="font-medium">Navigate to Onboarding</span>
									<MdCheck className="text-xl text-indigo-600" />
								</button>

								<button
									onClick={async () => {
										const {
											interest,
											role,
											letters,
											words,
											sentences,
										} = await getPronunciationAssessmentContent();
										const speechElements = [...letters, ...words, ...sentences];

										const progress = {
											sessionId: generateUUID(),
											speechElements: speechElements.map((el) => ({ word: el })),
											isAssessment: true,
											interest,
											role,
										};

										await saveToStorage("pronunciationProgress", progress);
										navigate("/pronunciationPractice");
									}}
									className="w-full p-3 bg-indigo-50 text-gray-700 rounded-xl hover:bg-indigo-100 transition-colors flex items-center justify-between"
								>
									<span className="font-medium">Take Pronunciation Assessment</span>
									<MdCheck className="text-xl text-indigo-600" />
								</button>
							</div>
						</div>
					)}

					{/* Logout Button */}
					{isAuthenticated() && (
						<button
							onClick={logout}
							className="w-full py-3 bg-red-500 text-white rounded-xl hover:bg-red-600 transition-colors flex items-center justify-center gap-2"
						>
							<MdLogout className="text-xl" />
							{t("logout")}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Settings;