import React, { createContext, useContext, useState, useEffect } from 'react';

interface ToastContextType {
	showToast: (message: string, duration?: number) => void;
	hideToast: () => void;
}

const ToastContext = createContext<ToastContextType>({
	showToast: () => { },
	hideToast: () => { },
});

export const useToast = () => useContext(ToastContext);

interface ToastProviderProps {
	children: React.ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [duration, setDuration] = useState(5000);

	const showToast = (msg: string, dur: number = 5000) => {
		setMessage(msg);
		setDuration(dur);
		setIsOpen(true);
	};

	const hideToast = () => {
		setIsOpen(false);
	};

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isOpen) {
			timer = setTimeout(() => {
				hideToast();
			}, duration);
		}
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [isOpen, duration]);

	return (
		<ToastContext.Provider value={{ showToast, hideToast }}>
			{children}
			{isOpen && (
				<div
					className={`
                        fixed bottom-4 left-1/2 transform -translate-x-1/2
                        bg-gray-800 text-white px-6 py-3 rounded-lg
                        shadow-lg z-50 animate-fade-in-up
                        max-w-md w-[90%] text-center
                    `}
					role="alert"
				>
					<p className="text-sm">{message}</p>
				</div>
			)}
		</ToastContext.Provider>
	);
};