import React, { useState, useEffect, useRef, forwardRef } from 'react';
import "./Testimonials.css";

interface TestimonialProps { }

const testimonials = [
	{
		id: 1,
		name: "Sarah Johnson",
		role: "Marketing Manager",
		quote: "This platform has significantly improved my professional English. The real-time feedback is invaluable!",
		image: "/api/placeholder/150/150"
	},
	{
		id: 2,
		name: "Michael Chen",
		role: "Software Developer",
		quote: "The CLB integration helped me showcase my English skills to potential employers. Highly recommended!",
		image: "/api/placeholder/150/150"
	},
	{
		id: 3,
		name: "Emily Rodriguez",
		role: "HR Specialist",
		quote: "The professional scenario simulations boosted my confidence in job interviews. It's been a game-changer!",
		image: "/api/placeholder/150/150"
	}
];

const Testimonials = forwardRef<HTMLDivElement, TestimonialProps>((props, ref) => {
	return (
		<div className="bg-blue-50 py-16" ref={ref}>
			<div className="max-w-6xl mx-auto px-4">
				<h1 className="hidden">Testimonials</h1>
				<h2 className="text-3xl font-bold text-center text-blue-800 mb-10">
					What Our Users Say
				</h2>
				<div className="relative overflow-hidden testimonials-container">
					<div className="testimonials-track">
						{/* Original testimonials */}
						{testimonials.map((testimonial) => (
							<div key={testimonial.id} className="testimonial-card">
								<div className="bg-white rounded-lg shadow-xl p-8">
									<div className="flex items-center mb-6">
										<img
											src={testimonial.image}
											alt={testimonial.name}
											className="w-20 h-20 rounded-full mr-4"
										/>
										<div>
											<h3 className="text-xl font-semibold text-blue-800">
												{testimonial.name}
											</h3>
											<p className="text-blue-600">{testimonial.role}</p>
										</div>
									</div>
									<p className="text-gray-700 italic">"{testimonial.quote}"</p>
								</div>
							</div>
						))}
						{/* Duplicated testimonials for seamless loop */}
						{testimonials.map((testimonial) => (
							<div key={`${testimonial.id}-clone`} className="testimonial-card">
								<div className="bg-white rounded-lg shadow-xl p-8">
									<div className="flex items-center mb-6">
										<img
											src={testimonial.image}
											alt={testimonial.name}
											className="w-20 h-20 rounded-full mr-4"
										/>
										<div>
											<h3 className="text-xl font-semibold text-blue-800">
												{testimonial.name}
											</h3>
											<p className="text-blue-600">{testimonial.role}</p>
										</div>
									</div>
									<p className="text-gray-700 italic">"{testimonial.quote}"</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
});

export default Testimonials;