import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => (
	<footer className="bg-gray-900 py-4">
		<div className="container mx-auto">
			<div className="flex flex-col items-center justify-center">
				<div className="text-center">
					<span className="text-sm text-gray-300">
						© 2024 Fluent Future. All rights reserved.
					</span>
				</div>
				<div className="text-center mt-2">
					<Link
						to="/terms-and-conditions"
						className="text-sm mx-2 text-gray-400 hover:text-gray-300 transition-colors"
					>
						Terms & Conditions
					</Link>
					<span className="text-gray-400">|</span>
					<Link
						to="/privacy-policy"
						className="text-sm mx-2 text-gray-400 hover:text-gray-300 transition-colors"
					>
						Privacy Policy
					</Link>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;