import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoRefresh, IoInformationCircle, IoChevronBack } from "react-icons/io5";
import { useServer } from "../../hooks/useServer";

interface ContentItem {
	content: string;
	scores: {
		pronScore: number;
		prosodyScore: number;
		accuracyScore: number;
		fluencyScore: number;
		compScore: number;
		[key: string]: any;
	};
	timestamp: string;
}

const PronunciationHistoryPage: React.FC = () => {
	const [contentList, setContentList] = useState<ContentItem[]>([]);
	const [loading, setLoading] = useState(true);
	const [popoverItem, setPopoverItem] = useState<ContentItem | null>(null);
	const { getContent } = useServer();
	const navigate = useNavigate();

	const fetchContent = async () => {
		try {
			setLoading(true);
			const content = await getContent();
			setContentList(content);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching content:", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchContent();
	}, []);

	const handleRetry = (content: string) => {
		navigate("/pronunciationPractice", { state: { speechElements: [content] } });
	};

	const CircularProgressBar: React.FC<{ percentage: number }> = ({ percentage }) => (
		<div className="relative w-16 h-16">
			<div
				className="w-full h-full rounded-full"
				style={{
					background: `conic-gradient(#3880ff ${percentage * 3.6}deg, #ededed 0deg)`,
				}}
			>
				<div className="absolute inset-0 flex items-center justify-center bg-white rounded-full m-1.5">
					<span className="text-sm font-semibold">
						{Math.round(percentage)}%
					</span>
				</div>
			</div>
		</div>
	);

	return (
		<div className="min-h-screen bg-gray-50">
			<header className="bg-white shadow">
				<div className="px-4 py-4 flex items-center">
					<button
						onClick={() => navigate("/pronunciation")}
						className="mr-2 text-gray-600"
					>
						<IoChevronBack size={24} />
					</button>
					<h1 className="text-xl font-semibold">Pronunciation History</h1>
				</div>
			</header>

			<main className="container mx-auto px-4 py-6">
				{loading ? (
					<div className="space-y-4">
						{[...Array(5)].map((_, index) => (
							<div
								key={index}
								className="bg-white p-4 rounded-lg shadow animate-pulse"
							>
								<div className="h-4 bg-gray-200 rounded w-3/5 mb-2"></div>
								<div className="h-4 bg-gray-200 rounded w-4/5 mb-2"></div>
								<div className="h-4 bg-gray-200 rounded w-2/5"></div>
							</div>
						))}
					</div>
				) : (
					<div className="divide-y divide-gray-200">
						{contentList.map((item, index) => (
							<div key={index} className="py-4 bg-white px-4 mb-2 rounded-lg shadow">
								<div className="flex items-center w-full">
									<div className="flex-grow">
										<h2 className="text-lg font-semibold">{item.content}</h2>
										<p className="text-sm text-gray-500">
											{new Date(item.timestamp).toLocaleString()}
										</p>
									</div>
									<div className="flex items-center space-x-4">
										<div className="relative">
											<CircularProgressBar percentage={item.scores.pronScore} />
											<button
												className="absolute -top-2 -right-2"
												onClick={() => setPopoverItem(item)}
											>
												<IoInformationCircle className="text-gray-500 w-5 h-5" />
											</button>
										</div>
										<button
											onClick={() => handleRetry(item.content)}
											className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
										>
											<IoRefresh className="mr-1" />
											Retry
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				{popoverItem && (
					<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
						<div className="bg-white rounded-lg p-6 max-w-md w-full">
							<h2 className="text-lg font-semibold mb-4">Detailed Scores</h2>
							<div className="space-y-4">
								<div>
									<p className="mb-1">Pronunciation Score: {popoverItem.scores.pronScore}%</p>
									<div className="w-full bg-gray-200 rounded-full h-2">
										<div
											className="bg-blue-500 h-2 rounded-full"
											style={{ width: `${popoverItem.scores.pronScore}%` }}
										></div>
									</div>
								</div>
								<div>
									<p className="mb-1">Prosody Score: {popoverItem.scores.prosodyScore}%</p>
									<div className="w-full bg-gray-200 rounded-full h-2">
										<div
											className="bg-purple-500 h-2 rounded-full"
											style={{ width: `${popoverItem.scores.prosodyScore}%` }}
										></div>
									</div>
								</div>
								<div>
									<p className="mb-1">Accuracy Score: {popoverItem.scores.accuracyScore}%</p>
									<div className="w-full bg-gray-200 rounded-full h-2">
										<div
											className="bg-green-500 h-2 rounded-full"
											style={{ width: `${popoverItem.scores.accuracyScore}%` }}
										></div>
									</div>
								</div>
								<div>
									<p className="mb-1">Fluency Score: {popoverItem.scores.fluencyScore}%</p>
									<div className="w-full bg-gray-200 rounded-full h-2">
										<div
											className="bg-yellow-500 h-2 rounded-full"
											style={{ width: `${popoverItem.scores.fluencyScore}%` }}
										></div>
									</div>
								</div>
								<div>
									<p className="mb-1">Comprehension Score: {popoverItem.scores.compScore}%</p>
									<div className="w-full bg-gray-200 rounded-full h-2">
										<div
											className="bg-orange-500 h-2 rounded-full"
											style={{ width: `${popoverItem.scores.compScore}%` }}
										></div>
									</div>
								</div>
								<div className="mt-4">
									<h3 className="font-semibold mb-2">Additional Scores:</h3>
									{Object.entries(popoverItem.scores).map(([key, value]) => {
										if (
											!["pronScore", "prosodyScore", "accuracyScore", "fluencyScore", "compScore"].includes(
												key
											)
										) {
											return (
												<p key={key} className="text-sm">
													{key}: {JSON.stringify(value)}
												</p>
											);
										}
										return null;
									})}
								</div>
							</div>
							<button
								onClick={() => setPopoverItem(null)}
								className="mt-6 w-full bg-gray-200 py-2 rounded-md hover:bg-gray-300 transition-colors"
							>
								Close
							</button>
						</div>
					</div>
				)}
			</main>
		</div>
	);
};

export default PronunciationHistoryPage;