import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
	IoVolumeHigh,
	IoVolumeLow,
	IoStop,
	IoHelpCircleOutline,
	IoHeadset,
	IoArrowBack,
} from "react-icons/io5";
import WordList from "../../components/pronunciation/WordList";
import Results from "../../components/pronunciation/Results";
import { useAzure } from "../../hooks/useAzure";
import { useServer } from "../../hooks/useServer";
import { PronunciationResult } from "../../types";
import PracticeWords from "./PracticeWords";
import { determinePercentile } from "../../utils/Helpers";

const PronunciationResultsPage: React.FC = () => {
	const { speakTextAsync, stopTextAsync } = useAzure();
	const { getContentBySessionId, getAudioURL } = useServer();
	const navigate = useNavigate();
	const params = useParams<{ sessionId: string }>();

	const [results, setResults] = useState<PronunciationResult[]>([]);
	const [current, setCurrent] = useState(0);
	const [isPlayingSlow, setIsPlayingSlow] = useState(false);
	const [isPlayingFast, setIsPlayingFast] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);
	const [showPopover, setShowPopover] = useState(false);

	const audioRef = React.createRef<HTMLAudioElement>();
	const [audioUrl, setAudioUrl] = useState<string | null>(null);
	const [audioPlaying, setAudioPlaying] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!params.sessionId) return;
				const content = await getContentBySessionId(params.sessionId);
				setResults(content);
				setIsLoading(false);
			} catch (err) {
				setError("Failed to load content");
				setIsLoading(false);
			}
		};
		fetchData();
	}, [params.sessionId, getContentBySessionId]);

	useEffect(() => {
		if (audioPlaying) {
			audioRef.current?.pause();
			setAudioPlaying(false);
		}

		if (isPlayingSlow) {
			stopTextAsync();
			setIsPlayingSlow(false);
		}

		if (isPlayingFast) {
			stopTextAsync();
			setIsPlayingFast(false);
		}

		setAudioUrl(null);

		(async () => {
			if (results.length === 0) return;

			const audioUrl = await getAudioURL(results[current].audio);
			console.log("audioUrl", audioUrl);
			setAudioUrl(audioUrl);
		})();
	}, [current, results]);

	if (isLoading) return <div className="p-4">Loading...</div>;
	if (error) return <div className="p-4">Error: {error}</div>;
	if (results.length === 0) return <div className="p-4">No results found</div>;

	const currentResult = results[current];
	const percentile = determinePercentile(
		currentResult.scores.pronScore,
		currentResult.averageScores
	);



	const getContentReason = () => {
		return (
			<span>
				This content is tailored for your interest in{" "}
				<strong>{currentResult.interest}</strong> and your role as{" "}
				<strong>{currentResult.role}</strong>. It's designed to improve:{" "}
				<strong>{currentResult.metric || ""}</strong>.
			</span>
		);
	};



	return (
		<div className="min-h-screen bg-white">
			<header className="bg-white shadow">
				<div className="px-4 py-2 flex items-center justify-between">
					<button
						onClick={() => navigate("/pronunciation")}
						className="p-2 hover:bg-gray-100 rounded-full"
					>
						<IoArrowBack className="w-6 h-6" />
					</button>
					<h1 className="text-lg font-semibold">Pronunciation Results</h1>
					<div className="w-6" /> {/* Spacer for alignment */}
				</div>
				<div className="h-1 bg-gray-200">
					<div
						className="h-full bg-blue-600 transition-all"
						style={{ width: `${((current + 1) / results.length) * 100}%` }}
					/>
				</div>
			</header>
			<main className="max-w-xl mx-auto p-4">
				<div className="relative">
					{currentResult.interest && currentResult.role && currentResult.metric && (
						<div className="absolute top-2 right-2">
							<button
								onClick={() => setShowPopover(!showPopover)}
								className="text-gray-500 hover:text-gray-700"
							>
								<IoHelpCircleOutline className="w-6 h-6" />
							</button>
							{showPopover && (
								<div className="absolute right-0 mt-2 p-4 bg-white shadow-lg rounded-lg w-64">
									<p>{getContentReason()}</p>
								</div>
							)}
						</div>
					)}

					<WordList words={currentResult.words} />
					<div className="flex justify-center gap-4 my-4">
						<button
							disabled={!audioUrl}
							onClick={() => {
								if (audioPlaying) {
									audioRef.current!.pause();
									audioRef.current!.currentTime = 0;
									setAudioPlaying(false);
									return;
								}
								setAudioPlaying(true);
								audioRef.current?.play();
							}}
							className="p-3 rounded-full bg-gray-100 hover:bg-gray-200 disabled:opacity-50"
						>
							{audioPlaying ? <IoStop /> : <IoHeadset />}
						</button>
						<button
							onClick={() => {
								if (isPlayingSlow) {
									console.log("stopping slow");
									stopTextAsync();
									setIsPlayingSlow(false);
									return;
								}
								setIsPlayingSlow(true);
								speakTextAsync({
									text: currentResult.content,
									rate: -100,
									cb: () => setIsPlayingSlow(false),
								});
							}}
							className="p-3 rounded-full bg-gray-100 hover:bg-gray-200"
						>
							{isPlayingSlow ? <IoStop /> : <IoVolumeLow />}
						</button>
						<button
							onClick={() => {
								if (isPlayingFast) {
									stopTextAsync();
									setIsPlayingFast(false);
									return;
								}
								setIsPlayingFast(true);
								speakTextAsync({
									text: currentResult.content,
									cb: () => setIsPlayingFast(false),
								});
							}}
							className="p-3 rounded-full bg-gray-100 hover:bg-gray-200"
						>
							{isPlayingFast ? <IoStop /> : <IoVolumeHigh />}
						</button>
					</div>
					<Results scores={currentResult.scores} />
					{percentile && (
						<div className="mt-6 flex flex-col items-center">
							<div className="relative">
								<div className="absolute -inset-1.5 bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg blur opacity-30"></div>
								<div className="relative px-8 py-4 bg-white rounded-lg shadow-xl border border-gray-100">
									<div className="flex flex-col items-center gap-2">
										<div className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
											Better pronunciation than
										</div>
										<div className="flex items-baseline gap-1">
											<span className="text-4xl font-extrabold text-blue-600">
												{percentile}%
											</span>
											<span className="text-lg text-gray-700 font-medium">
												of all speakers
											</span>
										</div>
										<div className="text-sm text-gray-500 mt-1">
											Keep up the excellent work! 🎯
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					{/* List of lessons showing metric and score */}
					<PracticeWords
						lessons={currentResult.lessons}
						sessionId={params.sessionId || ""}
						current={current}
					/>


					<button
						onClick={() =>
							current < results.length - 1
								? setCurrent(current + 1)
								: navigate("/pronunciation")
						}
						className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700 mt-4"
					>
						{current === results.length - 1 ? "Finish" : "Next"}
					</button>
				</div>
			</main>
			<audio
				style={{ display: "none" }}
				ref={audioRef}
				src={audioUrl || ""}
				onEnded={() => setAudioPlaying(false)}
			/>
		</div>
	);
};

export default PronunciationResultsPage;
