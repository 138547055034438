import { useState } from 'react';
import { IoMicOutline, IoTextOutline, IoCloseOutline } from 'react-icons/io5';
import { PronunciationSettingsProps } from "../../types";

interface PronunciationSettingsComponentProps {
	showSettings: boolean;
	setShowSettings: (showSettings: boolean) => void;
	settings: PronunciationSettingsProps;
	setSettings: (
		settings:
			| PronunciationSettingsProps
			| ((prevSettings: PronunciationSettingsProps) => PronunciationSettingsProps)
	) => void;
}

const PronunciationSettings: React.FC<PronunciationSettingsComponentProps> = ({
	showSettings,
	setShowSettings,
	settings,
	setSettings,
}) => {
	const handleSettingsChange = (
		setting: keyof PronunciationSettingsProps,
		value: any
	) => {
		setSettings((prevSettings: PronunciationSettingsProps) => {
			if (prevSettings) {
				return { ...prevSettings, [setting]: value };
			}
			return prevSettings;
		});
	};

	if (!showSettings) return null;

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
			<div className="bg-white rounded-lg w-full max-w-md mx-4">
				{/* Header */}
				<div className="border-b border-gray-200">
					<div className="px-4 py-3 flex justify-between items-center">
						<h2 className="text-lg font-semibold">Pronunciation Settings</h2>
						<button
							onClick={() => setShowSettings(false)}
							className="text-gray-500 hover:text-gray-700"
						>
							<IoCloseOutline size={24} />
						</button>
					</div>
				</div>

				{/* Content */}
				<div className="p-4">
					<div className="space-y-4">
						{/* Auto Start Recording */}
						<div className="flex items-center justify-between">
							<label className="text-gray-700">Auto Start Recording</label>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									className="sr-only peer"
									checked={settings?.autoStart || false}
									onChange={(e) => handleSettingsChange("autoStart", e.target.checked)}
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</div>

						{/* Show Phoneme */}
						<div className="flex items-center justify-between">
							<label className="text-gray-700">Show Phoneme</label>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									className="sr-only peer"
									checked={settings?.showPhoneticRepresentation || false}
									onChange={(e) => handleSettingsChange("showPhoneticRepresentation", e.target.checked)}
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</div>

						{/* Show Definition */}
						<div className="flex items-center justify-between">
							<label className="text-gray-700">Show Definition</label>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									className="sr-only peer"
									checked={settings?.showDefinition || false}
									onChange={(e) => handleSettingsChange("showDefinition", e.target.checked)}
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</div>

						{/* Show Practice Sentence */}
						<div className="flex items-center justify-between">
							<label className="text-gray-700">Show Practice Sentence</label>
							<label className="relative inline-flex items-center cursor-pointer">
								<input
									type="checkbox"
									className="sr-only peer"
									checked={settings?.showExample || false}
									onChange={(e) => handleSettingsChange("showExample", e.target.checked)}
								/>
								<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PronunciationSettings;