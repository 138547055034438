// LiveTutorProvider.tsx

import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { Character3D } from '../pages/livetutor/Character3D';
import { BlendShapeProvider } from './BlendShapeProvider';
import { FaExclamation } from 'react-icons/fa';
import { MdClose, MdChat, MdSettings, MdSend, MdStopCircle, MdMic, MdError, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import LiveTutorSettingsModal from '../pages/livetutor/LiveTutorSettings';
import { useStorage } from './useStorage';
import { LiveTutorSettings, Message } from '../types';
import { AudioRecorder } from '../utils/AudioRecorder';
import { useServer } from './useServer';
import { useAzure } from './useAzure';
import { split } from "sentence-splitter";
import { reduceByRole } from '../utils/Helpers';
import P from '../helper/P';
import { useTour } from '@reactour/tour';

interface LiveTutorContextType {
	isHovered: boolean;
	setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
	showMessageLog: boolean;
	setShowMessageLog: React.Dispatch<React.SetStateAction<boolean>>;
	showSettings: boolean;
	setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
	isRecording: boolean;
	setIsRecording: React.Dispatch<React.SetStateAction<boolean>>;
	suggestion: string | null;
	setSuggestion: React.Dispatch<React.SetStateAction<string | null>>;
	show: boolean;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;

	speechState: {
		messageQueue: string[];
		currentSpeechIndex: number;
	};
	setSpeechState: React.Dispatch<React.SetStateAction<{
		messageQueue: string[];
		currentSpeechIndex: number;
	}>>;

	messages: { role: string; content: string }[];
	setMessages: React.Dispatch<React.SetStateAction<{ role: string; content: string }[]>>;
	liveTutorSettings: any;
	setLiveTutorSettings: React.Dispatch<React.SetStateAction<any>>;
	handleSuggestion: () => void;
	handleMessageQueue: () => void;
	handleChat: (message: Message | string, add_to_log?: boolean) => Promise<string | null>;
	systemMessageRef: React.MutableRefObject<string | null>;
	handleSpeak: (message: string) => void;
}

const LiveTutorContext = createContext<LiveTutorContextType | undefined>(undefined);

export const useLiveTutor = () => {
	const context = useContext(LiveTutorContext);
	if (!context) {
		throw new Error('useLiveTutor must be used within a LiveTutorProvider');
	}
	return context;
};

interface LiveTutorProviderProps {
	children: React.ReactNode;
}

export const LiveTutorProvider: React.FC<LiveTutorProviderProps> = ({ children }) => {
	const { isOpen, currentStep, setDisabledActions } = useTour();

	const { useLocalStorage } = useStorage();

	const { speakTextAsync, stopTextAsync } = useAzure();
	const { chat, transcribe, user, translate } = useServer();


	const [show, setShow] = useState(true);
	const [isHovered, setIsHovered] = useState(false);
	const [showMessageLog, setShowMessageLog] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const [suggestion, setSuggestion] = useState<string | null>(null);

	const [speechState, setSpeechState] = useState({
		messageQueue: [] as string[],
		currentSpeechIndex: 0
	});


	const [messages, setMessages] = useState<{ role: string; content: string }[]>([]);
	const [liveTutorSettings, setLiveTutorSettings] = useLocalStorage<LiveTutorSettings>("liveTutorSettings", {
		volume: 100,
		liveConversation: false,
		autoPlayMessages: true,
		speakingRate: -20,
		nativeLanguage: false
	});

	const messagesRef = useRef<{ role: string; content: string }[]>(messages);
	const vadRef = useRef<any>(null);
	const recorderRef = useRef<AudioRecorder | null>(null);
	const nextSpeechTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const systemMessageRef = useRef<string | null>(`You are a friendly AI tutor named Sarah. You are teaching English to beginners.
Keep your responses short and simple.`);

	useEffect(() => {
		messagesRef.current = messages;
	}, [messages]);


	useEffect(() => {
		console.log("Current step", currentStep, isOpen);
		if (isOpen) {
			setTimeout(() => {
				console.log("Current step", currentStep);
				switch (currentStep) {
					case 0:
						handleSpeak("Hi, Welcome to Fluent Future. I will be helping you improve your English speaking skills.");
						break;
					case 1:
						handleSpeak(`Click on the text to translate it to ${user.nativeLanguage}.`);
						break;
					case 2:
						handleSpeak(`Great! You can talk to me in ${user.nativeLanguage} or English. Go ahead and ask me a question by clicking on the microphone.`);
						break;
					case 3:
						handleSpeak("You can also open the chat log and type your questions there.");
						break;
					default:
						break;
				}
			}, currentStep === 0 ? 2000 : 200);
		}
	}, [currentStep, isOpen]);


	useEffect(() => {
		const { currentSpeechIndex, messageQueue } = speechState;
		if (currentSpeechIndex === -1) {
			stopTextAsync();
			return;
		}

		if (currentSpeechIndex >= 0 && currentSpeechIndex < messageQueue.length) {
			const currentSentence = messageQueue[currentSpeechIndex];
			const newMessages = [...messagesRef.current, { role: 'assistant', content: currentSentence }] as Message[];
			console.log("updated messages", newMessages);
			setMessages(newMessages);

			nextSpeechTimeoutRef.current && clearTimeout(nextSpeechTimeoutRef.current);

			stopTextAsync();

			// Speak the current sentence
			speakTextAsync({
				text: currentSentence,
				rate: liveTutorSettings.speakingRate,
				voice: 'en-US-AvaNeural',
				updateBlendShapes: true,
				cb: () => {
					nextSpeechTimeoutRef.current = setTimeout(() => {
						setSpeechState(prevState => {
							const { currentSpeechIndex, messageQueue } = prevState;

							if (currentSpeechIndex < messageQueue.length - 1) {
								return {
									...prevState,
									currentSpeechIndex: currentSpeechIndex + 1
								};
							} else if (!isOpen) {
								return {
									messageQueue: [],
									currentSpeechIndex: -1
								};
							} else {
								return prevState;
							}
						});
					}, 400);
				}
			});
		} else {
			setSpeechState({
				messageQueue: [],
				currentSpeechIndex: -1
			});
		}
	}, [speechState.currentSpeechIndex, speechState.messageQueue]);


	const startRecord = async () => {
		try {
			if (!recorderRef.current) {
				recorderRef.current = new AudioRecorder();
				await recorderRef.current.init();
			}

			recorderRef.current.start();
		} catch (error) {
			console.error("Error starting recording:", error);
		}
	};

	const stopRecord = async () => {
		if (recorderRef.current && recorderRef.current.isRecording()) {
			const blob = await recorderRef.current.stop();
			if (blob) {
				const nativeLanguage = liveTutorSettings.nativeLanguage ? user.nativeLanguage : null;

				const message = await transcribe(blob, nativeLanguage);
				if (!message) {
					console.error("Error transcribing audio");
					return;
				}

				handleChat(message);

				if (isOpen) {
					setDisabledActions(false);
				}

			}
		}
	};

	useEffect(() => {
		const initializeVAD = async () => {
			try {
				if (typeof window.vadit === 'function') {
					window.vadit(() => setIsRecording(true), () => setIsRecording(false)).then((vad: any) => {
						console.log('VAD initialized');
						vadRef.current = vad;
					});
				}

			} catch (error) {
				console.error("Error initializing VAD:", error);
				// Handle the error (e.g., show an error message to the user)
			}
		};
		initializeVAD();
		return () => {
			if (vadRef.current) {
				vadRef.current.pause();
			}
		};
	}, []);


	useEffect(() => {

		if (vadRef.current) {
			if (liveTutorSettings.liveConversation) {
				console.log("Starting VAD");
				vadRef.current.start();
			} else {
				console.log("Stopping VAD");
				vadRef.current.pause();
			}
		}
	}, [liveTutorSettings.liveConversation]);

	useEffect(() => {
		// clear everything
		if (isRecording) {
			setSuggestion(null);
			setSpeechState({
				messageQueue: [],
				currentSpeechIndex: -1
			});

			stopTextAsync();
			startRecord();
		} else {
			stopRecord();
		}
	}, [isRecording]);

	const handleSuggestion = async () => {
		if (suggestion) {
			handleSpeak(suggestion);
			setSuggestion(null);
		}
	}

	const handleSpeak = async (message: string) => {
		const sentences = split(message).map((sentence) => sentence.raw).filter((sentence) => sentence.trim().length > 0);

		setSpeechState({
			messageQueue: sentences,
			currentSpeechIndex: 0
		});
	}

	const handleMessageQueue = async () => {
		console.log("Handling message queue");
		setSpeechState(prevState => {
			const { currentSpeechIndex, messageQueue } = prevState;

			if (currentSpeechIndex < messageQueue.length - 1) {
				return {
					...prevState,
					currentSpeechIndex: currentSpeechIndex + 1
				};
			} else if (!isOpen) {
				// Clear the queue when we reach the end
				return {
					messageQueue: [],
					currentSpeechIndex: -1
				};
			}

			return prevState; // No change if none of the conditions are met
		});
	}

	const clearMessageQueue = () => {
		setSpeechState({
			messageQueue: [],
			currentSpeechIndex: -1
		});
	}


	const handleChat = async (message: Message | string, add_to_log: boolean = true): Promise<string | null> => {

		/*
- Targetted question about image
- Generate image
- Translate to native language
- Practice pronunciation
- Spell the word
- Get definition
- Show synonyms
- 
		*/

		// check if message is string
		if (typeof message === "string") {
			message = { role: "user", content: message } as Message;
			if (add_to_log) {
				// then we need to make sure its in english
				const translated = await translate(message.content, "auto", "en");
				message.translated = translated;
			}
		}

		let newMessages = [...messagesRef.current] as Message[];

		if (message) {
			newMessages = [...messagesRef.current, message] as Message[];
			if (add_to_log) setMessages(newMessages);
		}

		// reduce 
		const reducedMessages = reduceByRole(newMessages);

		// Send chat message
		const messagesToSend = [
			...reducedMessages.slice(-10).map((message) => ({ role: message.role, content: message.translated || message.content })),
			{ role: "system", content: systemMessageRef.current },
		];

		const response = (await chat(messagesToSend)).trim()

		if (!response) {
			console.error("Error sending chat message");
			return "";
		}

		if ((message && add_to_log)) { // || state === "start"
			handleSpeak(response);
		} else {
			setSuggestion(response);
		}

		return response;
	};


	return (
		<LiveTutorContext.Provider
			value={{
				isHovered,
				setIsHovered,
				showMessageLog,
				setShowMessageLog,
				showSettings,
				setShowSettings,
				isRecording,
				setIsRecording,
				suggestion,
				setSuggestion,
				speechState,
				setSpeechState,
				show,
				setShow,

				messages,
				setMessages,
				liveTutorSettings,
				setLiveTutorSettings,
				handleSuggestion,
				handleMessageQueue,
				handleChat,
				systemMessageRef,
				handleSpeak
			}}
		>

			{children}
			<div
				className={`

			tutorial-step-0
			${show ? 'block' : 'hidden'}
            fixed
            z-10
            flex
            items-end
            ${isHovered ? 'opacity-40' : 'opacity-100'}
            top-0
            left-1/2
            -translate-x-1/2
            h-[40vh]
            w-full
            md:top-auto
            md:left-auto
            md:translate-x-0
            md:translate-y-0
            md:bottom-0
            ${showMessageLog ? 'md:right-[400px]' : 'md:right-0'}
            md:h-1/2
            md:w-auto
          `}
			>

				<Canvas
					style={{
						background: 'transparent',
					}}
					camera={{
						position: [0, 0.1, 1.4],
						fov: 30,
						near: 0.1,
						far: 1000,
						zoom: 1,
					}}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					<ambientLight intensity={0.3} />
					<Character3D />
				</Canvas>


				<NotificationIcon />
				<SpeechBubble />
				{(!isOpen || currentStep >= 2) && <RecordingButton />}
			</div>


			{(!isOpen || currentStep >= 3) && <ControlButtons />}
			<MessageLogPanel />
			<SettingsModal />


		</LiveTutorContext.Provider>
	);
};

// Separate components for each UI element
const NotificationIcon = () => {
	const { suggestion, handleSuggestion } = useLiveTutor();

	return suggestion ? (
		<button
			className="
		  absolute 
		  top-0
		  left-1/2 
		  -translate-x-1/2 
		  md:left-auto 
		  md:right-20 
		  flex items-center justify-center 
		  bg-red-500 text-white font-bold 
		  rounded-full w-12 h-16 
		  text-sm z-20 
		  hover:scale-110 
		  transition-transform duration-200 
		  cursor-pointer"
			onClick={handleSuggestion}
		>
			<FaExclamation className="text-2xl" />
		</button>
	) : null;
};

const SpeechBubble = () => {
	const { speechState, setSpeechState, handleMessageQueue } = useLiveTutor();
	const { isOpen, currentStep, setDisabledActions } = useTour();

	return speechState.messageQueue.length > 0 ? (
		<div className="
        absolute 

		top-14
    	-translate-y-full

        left-1/2 

        md:left-auto 
        md:right-[150px]
        bg-white text-black 
        p-4 w-64 z-30 
        transition-all duration-300 
        ease-in-out rounded-lg 
        shadow-lg border border-gray-200 
        animate-fadeIn tutorial-step-1"
		>
			{/* Close button */}
			{(!isOpen) && <button
				onClick={() => {
					setSpeechState({
						messageQueue: [],
						currentSpeechIndex: -1
					});
				}}
				className="absolute top-[-12px] right-[-12px] bg-gray-100 text-gray-600 hover:bg-gray-200 
                    rounded-full w-7 h-7 flex items-center justify-center shadow-sm border border-gray-300 
                    transition-all duration-200 hover:scale-105"
			>
				<MdClose className="text-lg" />
			</button>}

			<div className="space-y-2 max-h-48 overflow-y-auto">
				{speechState.messageQueue.map((message: string, index: number) => (
					<P
						key={message}
						className={`
								
								text-sm leading-relaxed ${index === speechState.currentSpeechIndex
								? 'text-black font-medium'
								: 'text-gray-400'
							}`}
						onClick={() => {
							if (isOpen) {
								setDisabledActions(false);
							}
						}}
					>
						{message}
					</P>
				))}
			</div>

			{speechState.currentSpeechIndex < speechState.messageQueue.length - 1 && (
				<div className="flex justify-end mt-2">
					<button
						onClick={handleMessageQueue}
						className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors text-sm"
					>
						Next
					</button>
				</div>
			)}
			<div className="absolute -bottom-2 right-8 w-4 h-4 bg-white transform rotate-45 border-r border-b border-gray-200"></div>
		</div>

	) : null;
};

const ControlButtons = () => {
	const { setShowMessageLog, setShowSettings, show, setShow, suggestion, handleSuggestion, } = useLiveTutor();
	const { setCurrentStep, isOpen, setDisabledActions } = useTour();
	const { stopTextAsync } = useAzure();

	return (
		<div className="fixed z-20 flex gap-2 flex-col top-4 right-4 md:top-auto md:bottom-80 md:right-4 ">
			<button
				onClick={() => {
					setShowMessageLog(prev => !prev)
					if (isOpen) {
						setCurrentStep((prev) => prev + 1);
					}
				}}

				className="p-3 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors tutorial-step-3"
			>
				<MdChat className="text-xl" />
			</button>
			<button
				onClick={() => setShowSettings(true)}
				className="p-3 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors"
			>
				<MdSettings className="text-xl" />
			</button>
			<button
				onClick={() => {
					if (!show && suggestion) {
						setShow(true);
						handleSuggestion();
					} else {
						if (show) {
							stopTextAsync();
						}

						setShow((prev) => !prev);
					}
				}}
				className={`p-3 rounded-full ${!show && suggestion ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'} text-white transition-colors`}
			>
				{!show && suggestion ? (
					<MdError className="text-xl" />
				) : show ? (
					<MdVisibilityOff className="text-xl" />
				) : (
					<MdVisibility className="text-xl" />
				)}
			</button>

		</div>
	);
};

const MessageLogPanel = () => {
	const { showMessageLog, setShowMessageLog, messages, handleChat } = useLiveTutor();
	const { isOpen, setIsOpen } = useTour();

	return showMessageLog ? (
		<div
			className={`fixed z-20
flex
flex-col
bg-white
rounded-lg
shadow-2xl
border border-gray-200
overflow-hidden
tutorial-step-4

/* Small screens (default) */
bottom-0
left-4

h-[calc(100vh-2rem)]

/* Large screens (md and up) */
md:top-auto
md:left-auto
md:right-4
md:h-[600px]
md:w-[400px]
`}
		>
			{/* Header */}
			<div className="flex justify-between items-center px-4 py-3 bg-gray-50 border-b">
				<h2 className="text-lg font-semibold text-gray-700">Message Log</h2>
				<button
					onClick={() => setShowMessageLog(false)}
					className="p-1 rounded-full hover:bg-gray-200 text-gray-500 hover:text-gray-700 transition-colors"
				>
					<MdClose className="text-xl" />
				</button>
			</div>

			{/* Messages Container */}
			<div className="flex-1 overflow-y-auto p-4 space-y-4">
				{messages
					.filter((message) => message.role !== 'system')
					.map((message, index) => (
						<div
							key={index}
							className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'
								}`}
						>
							<div
								className={`
	max-w-[80%]
	p-3
	rounded-lg
	${message.role === 'user'
										? 'bg-blue-500 text-white rounded-br-none'
										: 'bg-gray-100 text-gray-800 rounded-bl-none'
									}
  `}
							>
								<P className="text-sm whitespace-pre-wrap">{message.content}</P>
							</div>
						</div>
					))}
			</div>

			{/* Input Form */}
			<div className="p-4 bg-gray-50 border-t">
				<form
					className="flex items-center gap-2"
					onSubmit={(e: any) => {
						e.preventDefault();
						const input = e.target.elements.messageInput;
						const message = input.value.trim();
						if (message) {
							if (isOpen) {
								setIsOpen(false);
							}

							handleChat(message);
							input.value = '';


						}
					}}
				>
					<input
						autoComplete="off"
						type="text"
						name="messageInput"
						placeholder="Type your message..."
						className="flex-1 px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
					/>
					<button
						type="submit"
						className="p-2.5 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
					>
						<MdSend className="text-xl" />
					</button>
				</form>
			</div>
		</div>
	) : null;
};

const SettingsModal = () => {
	const { showSettings, setShowSettings, liveTutorSettings, setLiveTutorSettings } = useLiveTutor();

	return (
		<LiveTutorSettingsModal
			showSettings={showSettings}
			setShowSettings={setShowSettings}
			settings={liveTutorSettings}
			setSettings={(key, value) => setLiveTutorSettings({ ...liveTutorSettings, [key]: value })}
		/>
	);
};



const RecordingButton = () => {
	const { isRecording, setIsRecording } = useLiveTutor();

	return (
		<div className={`absolute bottom-8 left-1/2 -translate-x-1/2  z-20 tutorial-step-2`}>
			<button
				onClick={() => setIsRecording(!isRecording)}
				className={`p-6 rounded-full text-white ${isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'} transition-colors`}
			>
				{isRecording ? <MdStopCircle className="text-4xl" /> : <MdMic className="text-4xl" />}
			</button>
		</div>
	);
};

export default LiveTutorProvider;