import React from "react";

interface CLBScoresProps {
	scores: {
		writing: { analysis: string; score: number };
		speaking: { analysis: string; score: number };
	} | null;
}

const CLBScoresList: React.FC<CLBScoresProps> = ({ scores }) => {
	if (!scores) return null;

	return (
		<div className="mt-8">
			<h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
				CLB Scores
			</h2>
			<div className="space-y-4">
				{Object.entries(scores).map(([skill, data]) => (
					<div
						key={skill}
						className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-200"
					>
						<div className="px-4 py-3 border-b border-gray-200">
							<h3 className="text-xl font-semibold text-gray-800 capitalize">
								{skill}
							</h3>
						</div>
						<div className="p-4">
							<div className="flex items-center space-x-4">
								<div className="w-16 h-16 flex items-center justify-center bg-blue-500 rounded-full">
									<span className="text-2xl font-bold text-white">
										{data.score}
									</span>
								</div>
								<p className="text-gray-600 flex-1">{data.analysis}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default CLBScoresList;