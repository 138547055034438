import React, { Fragment, useRef, useState } from "react";
import { MdMic, MdStop, MdSend } from "react-icons/md";
import { useServer } from "../../hooks/useServer";

interface ChatInputProps {
	input: string;
	setInput: React.Dispatch<React.SetStateAction<string>>;
	inputType: string; // either "text" or "audio"
	sendMessage: (input: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({
	input,
	setInput,
	inputType,
	sendMessage,
}) => {
	const { transcribe } = useServer();
	const [isRecording, setIsRecording] = useState(false);
	const mediaRecorderRef = useRef<MediaRecorder | null>(null);

	const startRecord = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: true,
			});
			const mediaRecorder = new MediaRecorder(stream);
			mediaRecorderRef.current = mediaRecorder;

			const audioChunks: Blob[] = [];
			mediaRecorder.ondataavailable = (event) => {
				audioChunks.push(event.data);
			};

			mediaRecorder.onstop = async () => {
				const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
				const { content } = await transcribe(audioBlob, "English");
				if (!content) {
					console.error("Error transcribing audio");
					return;
				}

				setInput(content);
				sendMessage(content);
				stream.getTracks().forEach((track) => track.stop());
			};

			mediaRecorder.start();
			setIsRecording(true);
		} catch (error) {
			console.error("Error starting recording:", error);
		}
	};

	const stopRecord = () => {
		if (mediaRecorderRef.current) {
			mediaRecorderRef.current.stop();
			setIsRecording(false);
		}
	};

	return (
		<div className="flex items-center p-4 max-w-3xl mx-auto bg-transparent">
			{inputType === "voice" ? (
				<div className="fixed bottom-4 left-1/2 transform -translate-x-1/2">
					<button
						onClick={isRecording ? stopRecord : startRecord}
						className={`w-14 h-14 rounded-full flex items-center justify-center text-white ${isRecording ? "bg-red-500" : "bg-blue-500"
							} hover:opacity-90 transition-opacity`}
					>
						{isRecording ? (
							<MdStop className="w-6 h-6" />
						) : (
							<MdMic className="w-6 h-6" />
						)}
					</button>
				</div>
			) : (
				<Fragment>
					<textarea
						value={input}
						placeholder="Type a message"
						onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
							if (e.shiftKey && e.key === 'Enter') {
								return;
							}
							if (e.key === 'Enter' && !e.shiftKey) {
								e.preventDefault();
								sendMessage(input.trim());
								setInput("");
								return;
							}
						}}
						onChange={(e) => setInput(e.target.value)}
						className="flex-grow bg-gray-100 rounded-full px-4 py-2 mr-2 resize-none overflow-hidden min-h-[40px] max-h-[120px] border-2 border-blue-200 focus:ring-2 focus:ring-blue-500 focus:outline-none"
						rows={1}
						style={{ height: 'auto' }}
					/>
					<button
						onClick={() => {
							if (input.trim()) {
								sendMessage(input);
								setInput("");
							}
						}}
						className="w-10 h-10 bg-blue-500 text-white rounded-full flex items-center justify-center hover:bg-blue-600 transition-colors"
					>
						<MdSend className="w-5 h-5" />
					</button>
				</Fragment>
			)}
		</div>
	);
};

export default ChatInput;