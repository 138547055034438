
import { MdSettings, MdPauseCircle, MdChat, MdClose, MdWarning, MdSend, MdStopCircle, MdMic } from 'react-icons/md';
import {
	useQuery,
} from '@tanstack/react-query'
import { FaExclamation } from 'react-icons/fa';
import { useState, useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Character3D from './Character3D';
import Whiteboard from './Whiteboard';
import { useAzure } from '../../hooks/useAzure';
import { useServer } from '../../hooks/useServer';
import { Message, LiveTutorSettings } from '../../types';
import { useStorage } from '../../hooks/useStorage';

import { reduceByRole } from '../../utils/Helpers';
import LiveTutorSettingsModal from './LiveTutorSettings';
import { useLoading } from '../../hooks/LoadingProvider';
import { useToast } from '../../hooks/ToastProvider';

import { AudioRecorder } from '../../utils/AudioRecorder';
import BasicLessonFlow from './whiteboard/BasicFlow';
import AdvancedLessonFlow from './whiteboard/AdvancedFlow';

import Header from './Header';
import BasicInterestFlow from './whiteboard/BasicInterestFlow';
import AdvancedInterestFlow from './whiteboard/AdvancedInterestFlow';
import PronunciationPracticePage from './whiteboard/Pronunciation';
import RoleplayConversation from './whiteboard/speaking/RoleplayConversation';
import { useAuth } from '../../hooks/useAuth';
import { useHotStreak } from '../../hooks/HotStreakProvider';
import PronunciationLesson from './whiteboard/PronunciationLesson';
import { useLiveTutor } from '../../hooks/LiveTutorProvider';
import { useTour } from '@reactour/tour';

declare global {
	interface Window {
		vadit: (
			onSpeechStart: () => void,
			onSpeechEnd: () => void
		) => Promise<any>;
	}
}

interface SpeechState {
	fullText: string;
	currentWordIndex: number;
	words: string[];
	wordBoundaries: Array<{
		text: string;
		textOffset: number;
		duration: number;
		audioOffset: number;
	}>;
}

const exerciseMapper = {
	"1b": "Word Picture Match",
	"2a": "Fill in the Blank",
	"4b": "Listen and Answer",
	"5a": "Roleplay Conversation",
	"6a": "Read and Answer",
} as { [key: string]: string; };

const LiveTutor: React.FC = () => {
	const [state, setState] = useState<
		"start" | "basic_flow" | "basic_lesson_by_interest" | "advanced_flow" | "exercise" | "lesson" | "pronunciation" | "roleplay"
	>("start");
	const { setIsOpen, isOpen } = useTour();


	// Message handling states
	//const [systemMessage, setSystemMessage] = useState<string | null>(null);
	// Providers

	const { setSuggestion } = useLiveTutor();

	const { getLesson, getLessonByInterest, getPronunciation, getRoleplayScenario,
		postBasicLesson, postLessonByInterest, postRoleplayResults, user, addXp, checkStreak } = useServer();

	const { useLocalStorage } = useStorage();
	const { showHotStreak } = useHotStreak();

	const [completedTour, setCompletedTour] = useLocalStorage<boolean>("completedTour", false);
	useEffect(() => {
		if (!completedTour) {
			setIsOpen(true);
		}
	}, []);

	useEffect(() => {
		if (!isOpen && !completedTour) {
			setCompletedTour(true);
		}
	}, [isOpen]);

	// Change the completed state to be an object with dates and lesson types
	const [completed, setCompleted] = useLocalStorage<any>('completed', {});

	// Helper function to check if a lesson is completed for today
	const isLessonCompleted = (lessonType: string) => {
		const today = new Date().toLocaleDateString();
		return completed[today]?.includes(lessonType);
	};

	// Helper function to mark a lesson as completed
	const markLessonAsCompleted = (lessonType: string) => {
		const today = new Date().toLocaleDateString();
		setCompleted((prev: any) => ({
			...prev,
			[today]: [...(prev[today] || []), lessonType]
		}));
		setState("start");
	};

	const resetLessons = () => {
		setCompleted({});
	}

	// Get basicLesson using useQuery
	const { data: lesson, isLoading: isLessonLoading, error: isLessonError } = useQuery({
		queryKey: ['lesson'],
		queryFn: getLesson, // will be updated to getBasicLesson or getAdvancedLesson
		staleTime: 999999999, // don't refetch
		refetchOnMount: false, // don't refetch on mount
		refetchOnReconnect: false, // don't refetch on reconnect
		retry: false, // retry failed requests up to 3 times
		enabled: Boolean(user && !isLessonCompleted("general")),
	});


	const { data: lesson_by_interest, isLoading: isLessonLoadingByInterest, error: isLessonErrorByInterest } = useQuery({
		queryKey: ['lesson_by_interest'],
		queryFn: getLessonByInterest, // will be updated to getBasicLesson or getAdvancedLesson
		staleTime: 999999999, // don't refetch
		refetchOnMount: false, // don't refetch on mount
		refetchOnReconnect: false, // don't refetch on reconnect
		retry: false, // retry failed requests up to 3 times
		enabled: Boolean(user && user?.learningGoals?.includes("interests") && !isLessonCompleted("interest")),
	});


	const { data: pronunciation_content, isLoading: isPronunciationLoading, error: isPronunciationError } = useQuery({
		queryKey: ['pronunciation_content'],
		queryFn: getPronunciation,
		staleTime: 999999999, // don't refetch
		refetchOnMount: false, // don't refetch on mount
		refetchOnReconnect: false, // don't refetch on reconnect
		retry: false, // retry failed requests up to 3 times
		enabled: Boolean(user && user?.learningGoals?.includes("pronunciation")),
	});

	const { data: roleplay_content, isLoading: isRoleplayLoading, error: isRoleplayError } = useQuery({
		queryKey: ['roleplay_content'],
		queryFn: getRoleplayScenario,
		staleTime: 999999999,
		refetchOnMount: false,
		refetchOnReconnect: false,
		retry: false,
		enabled: Boolean(user && user?.learningGoals?.includes("conversation") && !isLessonCompleted("conversation")),
	});


	useEffect(() => {
		setSuggestion(null);
		(async () => {
			if (state !== "start") {
				checkStreak();
			}
		})();
	}, [state]);

	return (
		<div className="h-full bg-gradient-to-br from-gray-900 to-slate-900 ">
			<Header
				exercise={state !== "start" ? "Lesson" : ""}
				back={() => setState("start")}
			/>

			<main className="relative flex-1">
				{state === "start" && (
					<div className="min-h-screen flex items-center justify-center p-4">
						<div className="w-full max-w-3xl mx-auto bg-white/95 backdrop-blur-sm p-8 rounded-2xl shadow-2xl">
							<h2 className="text-4xl font-bold mb-6 bg-clip-text text-transparent 
                                bg-gradient-to-r from-blue-500 to-purple-500 text-center">
								Daily Lessons
							</h2>
							<p className="text-gray-600 mb-8 leading-relaxed text-center">
								Choose a lesson that suits your goals for today.
							</p>

							{/* Lesson Cards */}
							<div className="space-y-6">
								{/* General Practice */}
								<div className="bg-gray-100 p-5 rounded-lg shadow-md hover:shadow-lg transition duration-300">
									<div className="flex justify-between items-center">
										<div>
											<h3 className="text-lg font-semibold text-gray-700">General Practice</h3>
											<p className="text-gray-500">Sharpen your skills with daily exercises.</p>
										</div>
										<div>
											{isLessonCompleted('general') ? (
												<div className="flex items-center text-green-500">
													<svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
													</svg>
													<span>Completed Today</span>
												</div>
											) : isLessonLoading ? (

												<div className="flex items-center justify-center">
													<div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500"></div>
													<span className="ml-3 text-purple-500 font-medium">Loading...</span>
												</div>
											) : isLessonError ? (
												<div className="bg-red-50 border border-red-200 rounded-lg p-4">
													<p className="text-red-600 text-sm">
														Sorry, we couldn't load the lesson. Please try again later.
													</p>

												</div>
											) : (
												<button
													onClick={() => setState("basic_flow")}
													className="bg-gradient-to-r from-blue-500 to-purple-500
					text-white py-2 px-6 rounded-full font-medium
					hover:from-blue-600 hover:to-purple-600
					transform hover:scale-105 transition duration-300
					shadow-md hover:shadow-xl focus:outline-none focus:ring-2 
					focus:ring-purple-400 focus:ring-opacity-50"
												>
													Start General Practice
												</button>
											)}
										</div>
									</div>
								</div>

								{/* Learn More About Your Interest */}
								{user?.learningGoals?.includes("interests") && (
									<div className="bg-gray-100 p-5 rounded-lg shadow-md hover:shadow-lg transition duration-300">
										<div className="flex justify-between items-center">
											<div>
												<h3 className="text-lg font-semibold text-gray-700">Learn About Your Interest</h3>
												<p className="text-gray-500">Deep dive into topics you love.</p>
											</div>
											{isLessonCompleted('interest') ? (
												<div className="flex items-center text-green-500">
													<svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
													</svg>
													<span>Completed Today</span>
												</div>
											) : isLessonLoadingByInterest ? (
												<div className="flex items-center justify-center">
													<div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500"></div>
													<span className="ml-3 text-purple-500 font-medium">Loading...</span>
												</div>
											) : isLessonErrorByInterest ? (
												<div className="bg-red-50 border border-red-200 rounded-lg p-4">
													<p className="text-red-600 text-sm">
														Sorry, we couldn't load the lesson. Please try again later.
													</p>

												</div>
											) : (
												<button
													onClick={() => setState("basic_lesson_by_interest")}
													className="bg-gradient-to-r from-blue-500 to-purple-500
				text-white py-2 px-6 rounded-full font-medium
				hover:from-blue-600 hover:to-purple-600
				transform hover:scale-105 transition duration-300
				shadow-md hover:shadow-xl focus:outline-none focus:ring-2 
				focus:ring-purple-400 focus:ring-opacity-50">
													Start Learning
												</button>

											)}




										</div>
									</div>
								)}

								{/* Learn About Your Professional Aspiration 
								<div className="bg-gray-100 p-5 rounded-lg shadow-md hover:shadow-lg transition duration-300">
									<div className="flex justify-between items-center">
										<div>
											<h3 className="text-lg font-semibold text-gray-700">Learn About Your Aspiration</h3>
											<p className="text-gray-500">Build knowledge towards your career goals.</p>
										</div>
										<button
											onClick={() => console.log('Aspiration')}
											className="bg-gradient-to-r from-blue-500 to-purple-500
			text-white py-2 px-6 rounded-full font-medium
			hover:from-blue-600 hover:to-purple-600
			transform hover:scale-105 transition duration-300
			shadow-md hover:shadow-xl focus:outline-none focus:ring-2 
			focus:ring-purple-400 focus:ring-opacity-50">
											Start Now
										</button>
									</div>
								</div>
								*/}

								{/* Add this to your lesson cards section */}
								{user?.learningGoals?.includes("pronunciation") && (
									<div className="bg-gray-100 p-5 rounded-lg shadow-md hover:shadow-lg transition duration-300">
										<div className="flex justify-between items-center">
											<div>
												<h3 className="text-lg font-semibold text-gray-700">Pronunciation Practice</h3>
												<p className="text-gray-500">Improve your pronunciation skills.</p>
											</div>
											{isLessonCompleted('pronunciation') ? (
												<div className="flex items-center text-green-500">
													<svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
													</svg>
													<span>Completed Today</span>
												</div>
											) : isPronunciationLoading ? (
												<div className="flex items-center justify-center">
													<div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500"></div>
													<span className="ml-3 text-purple-500 font-medium">Loading...</span>
												</div>
											) : isPronunciationError ? (
												<div className="bg-red-50 border border-red-200 rounded-lg p-4">
													<p className="text-red-600 text-sm">
														Sorry, we couldn't load the practice. Please try again later.
													</p>
												</div>
											) : (
												<button
													onClick={() =>
														setState("pronunciation")
													}
													className="bg-gradient-to-r from-blue-500 to-purple-500
                    text-white py-2 px-6 rounded-full font-medium
                    hover:from-blue-600 hover:to-purple-600
                    transform hover:scale-105 transition duration-300
                    shadow-md hover:shadow-xl focus:outline-none focus:ring-2 
                    focus:ring-purple-400 focus:ring-opacity-50"
												>
													Start Practice
												</button>
											)}
										</div>
									</div>
								)}

								{user?.learningGoals?.includes("conversation") && (
									<div className="bg-gray-100 p-5 rounded-lg shadow-md hover:shadow-lg transition duration-300">
										<div className="flex justify-between items-center">
											<div>
												<h3 className="text-lg font-semibold text-gray-700">Conversation Practice</h3>
												<p className="text-gray-500">Practice real-life conversations and scenarios.</p>
											</div>
											{isLessonCompleted('conversation') ? (
												<div className="flex items-center text-green-500">
													<svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
													</svg>
													<span>Completed Today</span>
												</div>
											) : isRoleplayLoading ? (
												<div className="flex items-center justify-center">
													<div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-purple-500"></div>
													<span className="ml-3 text-purple-500 font-medium">Loading...</span>
												</div>
											) : isRoleplayError ? (
												<div className="bg-red-50 border border-red-200 rounded-lg p-4">
													<p className="text-red-600 text-sm">
														Sorry, we couldn't load the conversation. Please try again later.
													</p>
												</div>
											) : (
												<button
													onClick={() => setState("roleplay")}
													className="bg-gradient-to-r from-blue-500 to-purple-500
            text-white py-2 px-6 rounded-full font-medium
            hover:from-blue-600 hover:to-purple-600
            transform hover:scale-105 transition duration-300
            shadow-md hover:shadow-xl focus:outline-none focus:ring-2 
            focus:ring-purple-400 focus:ring-opacity-50"
												>
													Start Conversation
												</button>
											)}
										</div>
									</div>
								)}

							</div>

							{/*Reset Lessons Button*/}
							<div className="mt-8">
								<button
									onClick={() => resetLessons()}
									className="bg-gradient-to-r from-gray-300 to-gray-400
									 text-gray-700 py-3 px-8 rounded-full font-medium
									 hover:from-gray-400 hover:to-gray-500
									 transform hover:scale-105 transition duration-300
									 shadow-md hover:shadow-xl focus:outline-none focus:ring-2 
									 focus:ring-gray-500 focus:ring-opacity-50"
								>
									Reset Lessons
								</button>
							</div>


							{/* See More Button 
							<div className="mt-8">
								<button
									onClick={() => console.log('See More')}
									className="bg-gradient-to-r from-gray-300 to-gray-400
									 text-gray-700 py-3 px-8 rounded-full font-medium
									 hover:from-gray-400 hover:to-gray-500
									 transform hover:scale-105 transition duration-300
									 shadow-md hover:shadow-xl focus:outline-none focus:ring-2 
									 focus:ring-gray-500 focus:ring-opacity-50"
								>
									See More
								</button>
							</div>
							*/}
						</div>
					</div>

				)}

				{/* general Lesson */}
				{state === "basic_flow" && lesson && (
					<div className='pt-20'>
						{user.englishLevel === 'beginner' ?

							<BasicLessonFlow
								lesson={lesson}
								onComplete={() => {
									postBasicLesson();
									markLessonAsCompleted('general');
									addXp(100);

								}}
							/>
							:
							<AdvancedLessonFlow
								lesson={lesson}
								onComplete={() => {
									postBasicLesson();
									markLessonAsCompleted('general');
									addXp(100);

								}}
							/>
						}

					</div>
				)}

				{state === "basic_lesson_by_interest" && lesson && (
					<div className='pt-20'>
						{user.englishLevel === 'beginner' ?

							<BasicInterestFlow
								words={lesson_by_interest.words}
								exercise={lesson_by_interest.exercise}
								conversation={lesson_by_interest.conversation.dialogue}
								questions={lesson_by_interest.questions}
								onComplete={() => {
									postLessonByInterest(lesson_by_interest.interest.translated);
									markLessonAsCompleted('interest');
									addXp(100);
								}}
							/>
							:
							<AdvancedInterestFlow
								words={lesson_by_interest.words}
								exercise={lesson_by_interest.exercise}
								scenario={lesson_by_interest.scenario}
								questions={lesson_by_interest.questions}


								onComplete={() => {
									postLessonByInterest(lesson_by_interest.interest.translated);
									markLessonAsCompleted('interest');
									addXp(100);

								}}
							/>
						}
					</div>
				)}

				{state === "pronunciation" && pronunciation_content && (
					<div className="max-w-2xl mx-auto p-6 mb-16 md:mb-0">
						<div className="min-h-[500px] bg-white rounded-lg shadow-lg p-6">
							<PronunciationLesson
								pronunciationContent={pronunciation_content}
								onComplete={() => {
									markLessonAsCompleted('pronunciation');
									addXp(100);
								}}
							/>

						</div>
					</div>
				)
				}

				{state === "roleplay" && roleplay_content && (
					<div className="max-w-2xl mx-auto p-6 mb-16 md:mb-0">
						<div className="min-h-[500px] bg-white rounded-lg shadow-lg p-6">
							<RoleplayConversation
								dialogue={roleplay_content.conversation}
								setResults={() => console.log('Results')}
								onComplete={() => {
									addXp(100);
									markLessonAsCompleted('conversation');

									postRoleplayResults();

								}}
							/>
						</div>
					</div>
				)}

			</main >
		</div >
	);
}

export default LiveTutor;